import React from 'react';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import 'react-toastify/dist/ReactToastify.css'

function PPRCard({ imageSrc, mainTitle, description1, description2 }) {
    return (
        <>
            <div className='flex space-x-6 mx-auto my-auto h-sm:hidden'>
                <div className='flex h-full' style={{ width: '900px' }}>

                    <div style={{ width: '390px' }}>
                        <img src={imageSrc} />
                    </div>
                    <div style={{ width: '510px' }} className='p-5'>
                        <div className='mt-4'>
                            <span className='text-3xl font-extrabold font-raleway'>{mainTitle}</span>
                        </div>
                        <div className='mt-4'>
                            <span className='text-sm'>{description1}</span>
                        </div>
                        <div className='mt-4'>
                            <span className='text-sm'>{description2}</span>
                        </div>

                    </div>
                </div>
            </div>
            {/* Componente responsivo para celular */}
            <div className='flex mx-auto my-auto h-sm:flex hidden px-4'>
                <div className='w-full h-full'>

                    <div className='w-full h-full'>
                        <img src={imageSrc} />
                    </div>
                    <div className='w-full h-full mt-2'>
                        <div className='mt-4'>
                            <span className='text-3xl font-extrabold font-raleway'>{mainTitle}</span>
                        </div>
                        <div className='mt-4'>
                            <span className='text-sm'>{description1}</span>
                        </div>
                        <div className='mt-4'>
                            <span className='text-sm'>{description2}</span>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default PPRCard;