import React from 'react';
import Modal from 'react-modal';
import './DetailsPPR.css';

Modal.setAppElement('#root');

function DetailsPPR({ isOpen, onRequestClose, lead }) {

    const closeModal = () => {
        onRequestClose();
    };

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                contentLabel="Detalhes do Modal"
                className='absolute inset-10 h-sm:inset-0 bg-white overflow-auto outline-none rounded-2xl h-[450px] w-[430px] h-sm:w-full m-auto mt-20 p-5'
                shouldCloseOnOverlayClick={true}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 1000,
                    },
                    content: {
                        zIndex: 1001,
                        height: '400px',
                        backgroundColor: '#004e80'
                    },
                }}
            >
                <div className='w-full h-auto flex justify-end'>
                    <button className='rounded-full h-9 w-9 text-white font-bold' onClick={onRequestClose} style={{ border: '4px solid white' }}>X</button>
                </div>
                <div className='flex flex-col justify-center items-center w-full flex-1 rounded-2xl'>
                    <h1 className='text-2xl text-white mb-2'>Detalhes</h1>
                    <ul className="p-0 m-0 w-full list-none text-white">
                        <li className="mb-0 text-sm h-7 mt-1">Protocolo: #{lead.id}</li>
                        <hr className='w-full border border-[rgba(0,0,0,0.1)]' />
                        <li className="mb-0 text-sm h-7 mt-1">Nome: {lead.name}</li>
                        <hr className='w-full border border-[rgba(0,0,0,0.1)]' />
                        <li className="mb-0 text-sm h-7 mt-1">Telefone: {lead.phonenumber}</li>
                        <hr className='w-full border border-[rgba(0,0,0,0.1)]' />
                        <li className="mb-0 text-sm h-7 mt-1">Telefone 2: {lead.phonenumber2}</li>
                        <hr className='w-full border border-[rgba(0,0,0,0.1)]' />
                        <li className="mb-0 text-sm h-7 mt-1">Valor: R${lead.creditValue}</li>
                        <hr className='w-full border border-[rgba(0,0,0,0.1)]' />
                        <li className="mb-0 text-sm h-7 mt-1">% Pago do fundo comum: {lead.percentValue}%</li>
                        <hr className='w-full border border-[rgba(0,0,0,0.1)]' />
                        <li className="mb-0 text-sm h-7 mt-1">Data de encerramento: {lead.finalDate}</li>
                        <hr className='w-full border border-[rgba(0,0,0,0.1)]' />
                        <li className="mb-0 text-sm h-7 mt-1">Status: {lead.status}</li>
                    </ul>
                </div>
            </Modal>
        </div>
    );
};

export default DetailsPPR;
