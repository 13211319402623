import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './ChatPPR.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { getChatByLeadId, newChat } from '../../utils/api';

Modal.setAppElement('#root'); // Define o elemento raiz para acessibilidade

function ChatPPR({ isOpen, onRequestClose, lead }) {

    const [chatContent, setChatContent] = useState([]);
    const [message, setMessage] = useState('');
     
    useEffect(() => {
        const getChat = async () => {
            const chatContent = await getChatByLeadId(lead.id);
            setChatContent(chatContent);
        }

        if (isOpen) {
            getChat();
        }
    }, [isOpen, lead.id]);
    
    const sendMessage = () => {        
        const data = {
            message: message,
            lead_id: lead.id,
            consultant_id: lead.idconsultant,
            referrer_id: lead.idreferrer,
            receiver: 0,
            sender: 1
        }

        console.log(data);
        newChat(data, lead.id);
        if(newChat){
            alert('mensagem enviada com sucesso')
        }
        setMessage('');
        
        getChatByLeadId(lead.id).then((chatContent) => {
            setChatContent(chatContent);
        });
    }
    
    return (
      <div>
        <Modal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          contentLabel="Chat Modal"
          className='absolute inset-10 h-sm:inset-0 bg-white overflow-auto outline-none rounded-2xl h-[450px] w-[430px] h-sm:w-full m-auto mt-20'
          shouldCloseOnOverlayClick={true}
          style={{
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 1000,
            },
            content: {
                zIndex: 1001,
                height: '400px',
                backgroundColor: '#004e80'
            },
        }}
        >
          <div className='w-full h-full'>
            <div className='h-9 w-full flex justify-center items-center border-b border-white'>
                <p className='text-xl text-white'>Chat com o(a) Consultor(a)</p>
            </div>
            <div>
                <div className='w-full h-72 py-1 overflow-y-auto'>
                    {chatContent.map((item) => (
                        <div className={item.sender === '1' ? 'w-11/12 bg-[#64b4f66c] border-r-4 border-[#64b4f6d5] ml-3.5 mr-1 mb-1 rounded-lg py-1' : 'w-11/12 bg-[#64b4f6b7] border-l-4 border-l-[rgb(0,162,255)] ml-3.5 mr-1 mb-1 rounded-lg pt-1 pb-1'}>
                            <p className={item.sender === '1' ? 'text-white m-0 px-1 pt-1' : 'm-0 pt-1 pl-2.5 pr-1 text-white'}>{item.message}</p>
                        </div>
                    ))}
                    
                </div>
                <div className='h-16 w-full flex'>
                    <div className='h-16 w-10/12'>
                        <textarea className='border border-[#5c5c5c] bg-white w-full h-12 m-1 rounded-md text-base p-1' onChange={(event) => setMessage(event.target.value)} value={message} placeholder="Digite sua mensagem..."></textarea>
                    </div>
                    <div className='flex justify-center items-center h-16 w-2/12'>
                        <button className='w-10 h-10 rounded-full border-0 cursor-pointer bg-white shadow-[6px_16px_30px_1px_rgba(0,0,0,0.75)]' onClick={() => sendMessage()} ><FontAwesomeIcon icon={faPaperPlane} /></button>
                    </div>
                </div>
            </div>
          </div>
        </Modal>
      </div>
    );
};

export default ChatPPR;
