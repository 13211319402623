import Navbar from './components/NavBar/Navbar.jsx';

function App() {
  return (
    <div className="container">
      <Navbar/>      
    </div>
  );
}

export default App;
