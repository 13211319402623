import React from 'react';
import './HomeAboutUS.css';
import 'react-toastify/dist/ReactToastify.css'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

function HomeAboutUS() {
    return (
        <>
            <div className='flex space-x-6 mx-auto my-auto h-sm:hidden'>
                <div className='flex h-80 ' style={{ width: '800px' }}>
                    <div style={{ width: '460px' }}>
                        <div className='mt-10'>
                            <span className='text-3xl font-extrabold font-raleway'>Conhecendo a Objetiva</span>
                        </div>
                        <div className='mt-4'>
                            <span className='text-sm'>Com anos de experiência no mercado a objetiva vem <br /> se destacando pelo atendimento e valor justo de <br /> compra de cotas canceladas.</span>
                        </div>
                        <Link to="/sobre">
                            <button className='mt-4 h-10 w-2/3 rounded-lg text-sm border-none text-white cursor-pointer' style={{ backgroundColor: '#c76f36' }}>Saiba mais</button>
                        </Link>
                    </div>
                    <div style={{ width: '340px' }}>
                        <img src="objetiva.png" alt="logo" />
                    </div>
                </div>
            </div>
            {/* Componente responsivo para celular */}
            <div className='flex mx-auto my-auto h-sm:flex hidden px-4'>
                <div className='h-full w-full'>
                    <div className='w-full h-34'>
                        <img src="objetiva-mobile.png" alt="logo" className='w-full h-full' />
                    </div>
                    <div className='w-full mt-4'>
                        <div className='mt-4'>
                            <span className='text-3xl font-extrabold font-raleway'>Conhecendo a Objetiva</span>
                        </div>
                        <div className='mt-4'>
                            <span className='text-base'>Com anos de experiência no mercado a objetiva vem <br /> se destacando pelo atendimento e valor justo de <br /> compra de cotas canceladas.</span>
                        </div>
                        <Link to="/sobre">
                            <button className='mt-4 h-10 w-full rounded-lg text-base border-none text-white cursor-pointer' style={{ backgroundColor: '#c76f36' }}>Saiba mais</button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HomeAboutUS;