import React, { useState, useEffect } from 'react';
import './ProfilePPR.css';
import { toast } from 'react-toastify';
import { editReferrerById } from '../../utils/api';
import { notify, notifyPassword } from '../../utils/notify';
import { getCookie, hasCookie } from 'cookies-next';
import jwtDecode from 'jwt-decode';
import { showPPR, cancelProfile } from '../PPR/utilsPPR'
import DataBankPPR from '../DataBankPPR/DataBankPPR'
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildingColumns } from '@fortawesome/free-solid-svg-icons';

Modal.setAppElement('#root');

function ProfilePPR({ isOpen, closeModal }) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [idUser, setIdUser] = useState('');
    const [chatModalOpen, setChatModalOpen] = useState(false);

    const openChatModal = () => {
        setChatModalOpen(true);
    };

    const closeChatModal = () => {
        setChatModalOpen(false);
    };

    var decodedToken = "";

    useEffect(() => {
        async function fetchData() {
            if (!hasCookie('token')) {
                return;
            }
            const token = getCookie('token');
            decodedToken = jwtDecode(token);
            setIdUser(decodedToken.referrerUser.id);
            setName(decodedToken.referrerUser.name);
            setEmail(decodedToken.referrerUser.email);
            setPhone(decodedToken.referrerUser.phonenumber);
        }
        fetchData();
    }, []);

    const checksInput = () => {
        var input = "";
        if (name === '') {
            input = "nome";
            notify(input);
            return false;
        } else if (phone === '') {
            input = "telefone";
            notify(input);
            return false;
        } else if (email === '') {
            input = "email";
            notify(input);
            return false;
        }
        return true;
    }

    const editProfile = async (event) => {
        event.preventDefault();

        if (!checksInput()) {
            return false;
        }

        var data;
        if (password === "" && confirmPassword === "") {
            data = {
                name: name,
                email: email,
                phonenumber: phone,
                password: "",
            };
        } else {
            if (password !== confirmPassword) {
                notifyPassword("As senhas não conferem");
            } else {
                data = {
                    name: name,
                    email: email,
                    phonenumber: phone,
                    password: password,
                };
            }

        }

        const id = toast.loading('Aguarde...', { duration: null });

        try {
            const response = await editReferrerById(idUser, data);

            if (response) {
                if (response.status === 409) {
                    toast.update(id, { render: 'Os dados permanecem idênticos aos já cadastrados!', type: 'info', isLoading: false, autoClose: 2500 });
                    console.log("Conflito de dados (status 409)");
                } else if (response.status === 200 || response.status === 201) {
                    toast.update(id, { render: 'Alteração realizada com sucesso!', type: 'success', isLoading: false, autoClose: 2500 });

                    setTimeout(function () {
                        showPPR();
                    }, 2500);
                }
            } else {
                console.log("Erro na resposta da API");
            }
        } catch (err) {
            console.log("Erro na requisição:", err);
        }
    };

    const changePassword = () => {
        const checkboxElement = document.getElementById('checkPassword');
        const inputElement = document.getElementById('profile_password');
        const inputElement2 = document.getElementById('profile_password2');

        const setStyles = (backgroundColor, pointerEvents) => {
            inputElement.style.backgroundColor = backgroundColor;
            inputElement2.style.backgroundColor = backgroundColor;
            inputElement.style.pointerEvents = pointerEvents;
            inputElement2.style.pointerEvents = pointerEvents;
        };

        if (checkboxElement.checked) {
            setStyles('#E3E3E3', 'auto');
        } else {
            setPassword('');
            setConfirmPassword('');
            setStyles('#afafaf', 'none');
        }
    };

    const cancelProfile = (event) => {
        event.preventDefault();
        const checkboxElement = document.getElementById('checkPassword');
        const inputElement = document.getElementById('profile_password');
        const inputElement2 = document.getElementById('profile_password2');
        inputElement.style.backgroundColor = '#afafaf';
        inputElement2.style.backgroundColor = '#afafaf';
        checkboxElement.checked = false;
        document.getElementById("profile").classList.add("hide");
        document.getElementById("ppr").classList.remove("hide");
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="Register Modal"
            className='absolute inset-10 h-sm:inset-0 border-gray-300 bg-white overflow-auto outline-none p-5 rounded-2xl w-1/3 h-sm:w-full mx-auto mt-20'
            shouldCloseOnOverlayClick={true}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1000,
                },
                content: {
                    zIndex: 1001,
                    height: '400px',
                    backgroundColor: '#004e80'
                },
            }}
        >

            <div className='w-full h-auto flex justify-between'>
                <button className='w-[173px] h-8 bg-[#c76f36] border-0 rounded-md mt-1 text-white cursor-pointer' onClick={openChatModal}>Dados Bancarios <FontAwesomeIcon icon={faBuildingColumns} /></button>
                <DataBankPPR isOpen={chatModalOpen} closeModal={closeChatModal} />
                <button className='rounded-full h-9 w-9 text-white font-bold' onClick={closeModal} style={{ border: '4px solid white' }}>X</button>
            </div>

            <h1 className='text-2xl text-center m-0 text-white'>Perfil</h1>
            <form className='px-10 space-y-4 h-sm:px-5'>
                <input type='text' value={name} onChange={e => setName(e.target.value)} placeholder="Nome" className='w-full bg-slate-200 rounded-lg h-10 p-3 font-normal' id='profile_name' name='profile_name' /><br />
                <input type='text' value={phone} onChange={e => setPhone(e.target.value)} placeholder="Telefone" className='w-full bg-slate-200 rounded-lg h-10 p-3 font-normal' id='profile_phone' name='profile_phone'></input>
                <input type='text' value={email} onChange={e => setEmail(e.target.value)} placeholder="Email para login" className='w-full bg-slate-200 rounded-lg h-10 p-3 font-normal' id='profile_mail' name='profile_mail' /><br />
                <div className='text-white'>
                    <label>
                        <input type="checkbox" id='checkPassword' onClick={changePassword} />
                        Senha
                    </label>
                    <div className='flex space-x-4'>
                        <div className='w-1/2'>
                            <input type='password' value={password} onChange={e => setPassword(e.target.value)} placeholder="Senha" className='w-full bg-[#afafaf] rounded-lg h-10 p-3 font-normal pointer-events-none' id='profile_password' name='profile_password' />
                        </div>
                        <div className='w-1/2'>
                            <input type='password' value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} placeholder="Senha" className='w-full bg-[#afafaf] rounded-lg h-10 p-3 font-normal pointer-events-none' id='profile_password2' name='profile_password' />
                        </div>
                    </div>
                </div>
                <div className='flex justify-center items-center'>
                    <button className='text-white rounded-lg border-0 w-full h-10 text-base cursor-pointer' style={{ backgroundColor: '#c76f36' }} onClick={editProfile}>Salvar</button>
                </div>
            </form>
        </Modal>
    );
}

export default ProfilePPR;
