import React from "react";
import ComponentContato from "../../components/FormContato/ComponentContato";
import Footer from "../../components/Footer/Footer";
import "./Contato.css"; 

function Contato() {
  return (
    <section className="flex flex-col absolute h-screen min-h-full w-full">
      <div>
        <img src="contact.png" alt='logo' />
      </div>
      <div className="w-full justify-center items-center pt-8" >
        <div className="w-full justify-center items-center flex">
          <span className="text-4xl font-extrabold">Entre em Contato</span>
        </div>
        <div className="w-full justify-center items-center flex pt-3">
          <span> Preencha o formulário e entraremos em contato em breve.</span>
        </div>
      </div>
      <div className="relative">
        <div className="absolute top-0 left-0 z-10 flex w-full justify-center items-center pt-7">
          <ComponentContato />
        </div>
        <div className="flex w-full z-0 justify-center items-center pt-96">
          <div style={{ width:'1100px', height: '430px'}}>
            <iframe className="maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.0159977181934!2d-46.634889628369415!3d-23.56786891494384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce590ab0f1386d%3A0xd1465a5d6e311356!2sObjetiva%20Cons%C3%B3rcio!5e0!3m2!1spt-BR!2sbr!4v1696444119436!5m2!1spt-BR!2sbr" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
      <div className="flex w-full z-0 pt-9">
        <Footer/> 
      </div>
    </section>
  );
}

export default Contato;
