import React from "react";
import Footer from "../../components/Footer/Footer";
import UsHowMuch from "../../components/UsHowMuch/UsHowMuch";
import UsDifferential from "../../components/UsDifferential/UsDifferential";
import UsAbout from "../../components/UsAbout/UsAbout";
import UsWeDo from "../../components/UsWeDo/UsWeDo";
import video from "../../assets/logo.mp4"; 

function Sobre() {
  return (
    <section className="flex flex-col absolute h-screen min-h-full w-full">
      <div className="relative">
        <div className="flex w-full h-full z-10 absolute items-center justify-center">
          <div className="mx-auto my-auto">
            <span className="text-3xl font-extrabold font-raleway text-white" id="sobre" >Quem somos</span>
          </div>
        </div>
        <img src="us_people.png" alt="logo" className='z-0 top-0 left-0 w-full' />
      </div>
      <div className="flex w-full z-0 pt-9 pb-9">
        <UsHowMuch />
      </div>
      <div className="flex w-full z-0 pt-9 pb-9">
        <div className="mx-auto text-center">
          <span className='text-3xl font-extrabold font-raleway pb-10'>Nossa marca</span>
          <video width="900px" height="100px" controls autoplay="autoplay" muted loop className="shadow-3xl mt-6 h-sm:px-4">
            <source src={video} type="video/mp4" />
          </video>
        </div>
      </div>
      <div className="flex w-full z-0 pt-9 pb-9">
        <UsDifferential />
      </div>
      <div className="flex w-full z-0 pt-9 pb-9">
        <UsAbout />
      </div>
      <div className="flex w-full z-0 pt-9 pb-9">
        <UsWeDo />
      </div>
      <div className="flex w-full z-0 pt-9">
        <Footer />
      </div>
    </section>
  );
}

export default Sobre;