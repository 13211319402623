import React from 'react';
import './HomeSteps.css';
import 'react-toastify/dist/ReactToastify.css'

function HomeSteps() {
    return (
        <>
            <div className='flex space-x-6 mx-auto my-auto h-sm:hidden'>
                <div className='flex h-96' style={{ width: '1150px', height: '450px' }}>
                    <div>
                        <div style={{ width: '250px' }}>
                            <span style={{ color: '#f5be88' }}>Para você</span>
                        </div>
                        <div className='mt-3'>
                            <span className='text-3xl font-extrabold font-raleway'>Passo a passo <br /> para vender o <br /> seu consórcio </span>
                        </div>
                        <div className='mt-3'>
                            <span className='text-sm'>Vender o seu consórcio nunca <br /> foi tão rápido, fácil e seguro </span>
                        </div>
                    </div>
                    <div className='w-1/2'>
                        <div className='w-full h-28 flex p-3'>
                            <div>
                                <img src="1.png" alt="logo" className='h-16 w-16' />
                            </div>
                            <div className='p-4 pt-0'>
                                <div className='font-bold'>
                                    <span>Preencha o formulário</span>
                                </div>
                                <div>
                                    <span className='text-sm'>
                                        Informe seus dados pessoais. <br />
                                        Garantimos a confidencialidade das <br />
                                        suas informações
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='w-full h-10 flex justify-center items-center'>
                            <img src="arrow_down.png" alt="logo" />
                        </div>
                        <div className='w-full h-28 flex p-3'>
                            <div>
                                <img src="2.png" alt="logo" className='h-16 w-16' />
                            </div>
                            <div className='p-4 pt-0'>
                                <div className='font-bold'>
                                    <span>Avaliação e proposta</span>
                                </div>
                                <div>
                                    <span className='text-sm'>
                                        Após recebermos seus documentos, <br />
                                        você receberá uma proposta <br />
                                        personalizada
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='w-full h-10 flex justify-center items-center'>
                            <img src="arrow_r.png" alt="logo" />
                        </div>
                    </div>
                    <div className='w-1/2'>
                        <div className='w-full h-28 flex p-3'>
                            <div>
                                <img src="3.png" alt="logo" className='h-16 w-16' />
                            </div>
                            <div className='p-4 pt-0'>
                                <div className='font-bold'>
                                    <span>Aceite a proposta</span>
                                </div>
                                <div>
                                    <span className='text-sm'>
                                        Gostou da proposta? Basta informar <br />
                                        sua decisão e concordar com os <br />
                                        termos apresentados
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='w-full h-10 flex justify-center items-center'>
                            <img src="arrow_down.png" alt="logo" />
                        </div>
                        <div className='w-full h-40 flex p-3'>
                            <div>
                                <img src="4.png" alt="logo" className='h-16 w-16' />
                            </div>
                            <div className='p-4 pt-0'>
                                <div className='font-bold'>
                                    <span>Efetivação do processo</span>
                                </div>
                                <div>
                                    <span className='text-sm'>
                                        Realizamos a compra de sua cota cancelada<br /> e assumimos 
                                        o direito de receber os valores <br /> futuros de seu 
                                        consorcio cancelado com <br /> o envio do
                                        extrato <br /> atualizado da cota e assinatura dos documentos.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='w-full h-10 flex justify-center items-center'>
                            <img src="arrow_down.png" alt="logo" />
                        </div>
                        <div className='w-full h-32 flex p-3'>
                            <div>
                                <img src="5.png" alt="logo" className='h-16 w-16' />
                            </div>
                            <div className='p-4 pt-0'>
                                <div className='font-bold'>
                                    <span>Receba seu dinheiro</span>
                                </div>
                                <div>
                                    <span className='text-sm'>
                                        Com o processo concluído, você terá seu <br />
                                        dinheiro em mãos, à vista! Assim, você <br />
                                        poderá usá-lo para realizar seus planos, <br />
                                        quitar dívidas ou investir.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Componente responsivo para celular */}
            <div className='flex space-x-6 mx-auto my-auto h-sm:flex hidden px-4'>
                <div className='h-full w-full'>
                    <div>
                        <div style={{ width: '250px' }}>
                            <span style={{ color: '#f5be88' }}>Para você</span>
                        </div>
                        <div className='mt-3'>
                            <span className='text-2xl font-extrabold font-raleway'>Passo a passo para <br /> vender o seu consórcio </span>
                        </div>
                        <div className='mt-3'>
                            <span className='text-base'>Vender o seu consórcio nunca <br /> foi tão rápido, fácil e seguro </span>
                        </div>
                    </div>
                    <div className='w-full mt-6 space-y-5'>
                        <div className='w-full flex px-3 mt-3'>
                            <div>
                                <img src="1.png" alt="logo" className='h-16 w-16' />
                            </div>
                            <div className='px-4'>
                                <div className='font-bold'>
                                    <span>Preencha o formulário</span>
                                </div>
                                <div>
                                    <span className='text-sm'>
                                        Informe seus dados pessoais. <br />
                                        Garantimos a confidencialidade das <br />
                                        suas informações
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='w-full flex justify-center items-center'>
                            <img src="arrow_down.png" alt="logo" />
                        </div>
                        <div className='w-full flex px-3 mt-3'>
                            <div>
                                <img src="2.png" alt="logo" className='h-16 w-16' />
                            </div>
                            <div className='px-4'>
                                <div className='font-bold'>
                                    <span>Avaliação e proposta</span>
                                </div>
                                <div>
                                    <span className='text-sm'>
                                        Após recebermos seus documentos, <br />
                                        você receberá uma proposta <br />
                                        personalizada
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='w-full flex justify-center items-center'>
                            <img src="arrow_down.png" alt="logo" />
                        </div>
                    </div>
                    <div className='w-full space-y-5'>
                        <div className='w-full flex px-3 mt-3'>
                            <div>
                                <img src="3.png" alt="logo" className='h-16 w-16' />
                            </div>
                            <div className='px-4'>
                                <div className='font-bold'>
                                    <span>Aceite a proposta</span>
                                </div>
                                <div>
                                    <span className='text-sm'>
                                        Gostou da proposta? Basta informar <br />
                                        sua decisão e concordar com os <br />
                                        termos apresentados
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='w-full flex justify-center items-center'>
                            <img src="arrow_down.png" alt="logo" />
                        </div>
                        <div className='w-full flex px-3 mt-3'>
                            <div>
                                <img src="4.png" alt="logo" className='h-16 w-16' />
                            </div>
                            <div className='px-4'>
                                <div className='font-bold'>
                                    <span>Efetivação do processo</span>
                                </div>
                                <div>
                                    <span className='text-sm'>
                                        Realizamos a compra de sua cota cancelada<br /> e assumimos 
                                        o direito de receber os valores <br /> futuros de seu 
                                        consorcio cancelado com <br /> o envio do
                                        extrato <br /> atualizado da cota e assinatura dos documentos.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='w-full flex justify-center items-center'>
                            <img src="arrow_down.png" alt="logo" />
                        </div>
                        <div className='w-full flex px-3 mt-3'>
                            <div>
                                <img src="5.png" alt="logo" className='h-16 w-16' />
                            </div>
                            <div className='px-4'>
                                <div className='font-bold'>
                                    <span>Receba seu dinheiro</span>
                                </div>
                                <div>
                                    <span className='text-sm'>
                                        Com o processo concluído, você terá seu <br />
                                        dinheiro em mãos, à vista! Assim, você <br />
                                        poderá usá-lo para realizar seus planos, <br />
                                        quitar dívidas ou investir.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HomeSteps;