import React from 'react';
import './AboutIntermediation.css';
import 'react-toastify/dist/ReactToastify.css'

function AboutIntermediation() {
    return (
        <>
            <div className='flex space-x-3 mx-auto my-auto h-sm:hidden'>
                <div className='flex' style={{ width: '1200px' }}>
                    <div className='w-1/3'>
                        <img src="man.png" alt="logo" />
                    </div>
                    <div className='w-1/3'>
                        <div className='h-36 pt-16 pl-3'>
                            <span className='text-3xl font-extrabold font-raleway whitespace-nowrap'>Como funciona a intermediação?</span>
                        </div>
                        <div className='w-full h-60 flex p-3'>
                            <div>
                                <img src="about_people.png" alt="logo" className='h-16 w-16' />
                            </div>
                            <div className='p-4 pt-0'>
                                <div className='font-bold'>
                                    <span>Quem é o <br />intermediário?</span>
                                </div>
                                <div>
                                    <span className='text-sm'>
                                        O intermediário é quem cuida de todo o <br />
                                        processo, desde a abordagem do <br />
                                        consorciado até a elaboração dos <br />
                                        documentos que instrumentalizam a <br />
                                        negociação.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='w-full h-60 flex p-3'>
                            <div>
                                <img src="about_calculator.png" alt="logo" className='h-16 w-16' />
                            </div>
                            <div className='p-4 pt-0'>
                                <div className='font-bold'>
                                    <span>Intermediação <br />vinculada à <br />calculadora</span>
                                </div>
                                <div>
                                    <span className='text-sm'>
                                        Segue a mesma rotina prática da <br />
                                        intermediação livre, porém o intermediário, <br />
                                        obrigatoriamente, precisa negociar com o <br />
                                        consorciado com base em nossa proposta <br />
                                        neste caso o intermediário recebe um  <br />
                                        percentual em cima dessa compra.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/3'>
                        <div className='h-36'></div>
                        <div className='w-full h-60 flex p-3'>
                            <div>
                                <img src="about_flag.png" alt="logo" className='h-16 w-16' />
                            </div>
                            <div className='p-4 pt-0'>
                                <div className='font-bold'>
                                    <span>Intermediação <br />livre</span>
                                </div>
                                <div>
                                    <span className='text-sm'>
                                    Nesse modelo o intermediário tem  a <br /> 
                                    liberdade de negociar livremente <br />
                                    o valor de compra da cota cancelada <br /> 
                                    respeitando os paramentos e limites de <br /> 
                                    proposta de nossa calculadora, correndo  por <br /> 
                                    sua conta e risco todas as despesas da <br />
                                    negociação.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='w-full h-60 flex p-3'>
                            <div>
                                <img src="about_settings.png" alt="logo" className='h-16 w-16' />
                            </div>
                            <div className='p-4 pt-0'>
                                <div className='font-bold'>
                                    <span>Disposições <br />Gerais</span>
                                </div>
                                <div>
                                    <span className='text-sm'>
                                        As formas de intermediação são <br />
                                        independentes e não podem ser <br />
                                        misturadas na mesma negociação, <br />
                                        ou seja, para a mesma cota.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Componente responsivo para celular */}
            <div className='flex space-x-3 mx-auto my-auto h-sm:flex hidden mt-72'>
                <div className='w-full'>
                    <div className='w-full h-full'>
                        <div className='h-32 pt-16 pl-3'>
                            <span className='text-xl font-extrabold font-raleway whitespace-nowrap'>Como funciona a intermediação?</span>
                        </div>
                        <div className='w-full h-52 flex p-3'>
                            <div>
                                <img src="about_people.png" alt="logo" className='h-16 w-16' />
                            </div>
                            <div className='p-4 pt-0'>
                                <div className='font-bold'>
                                    <span>Quem é o <br />intermediário?</span>
                                </div>
                                <div>
                                    <span className='text-sm'>
                                        O intermediário é quem cuida de todo o <br />
                                        processo, desde a abordagem do <br />
                                        consorciado até a elaboração dos <br />
                                        documentos que instrumentalizam a <br />
                                        negociação.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='w-full h-52 flex p-3'>
                            <div>
                                <img src="about_calculator.png" alt="logo" className='h-16 w-16' />
                            </div>
                            <div className='p-4 pt-0'>
                                <div className='font-bold'>
                                    <span>Intermediação vinculada à <br />calculadora</span>
                                </div>
                                <div>
                                    <span className='text-sm'>
                                        Segue a mesma rotina prática da <br />
                                        intermediação livre, porém o intermediário, <br />
                                        obrigatoriamente, precisa negociar com o <br />
                                        consorciado com base em nossa proposta <br />
                                        neste caso o intermediário recebe um  <br />
                                        percentual em cima dessa compra.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='w-full h-52 flex p-3'>
                            <div>
                                <img src="about_flag.png" alt="logo" className='h-16 w-16' />
                            </div>
                            <div className='p-4 pt-0'>
                                <div className='font-bold'>
                                    <span>Intermediação livre</span>
                                </div>
                                <div>
                                    <span className='text-sm'>
                                        Nesse modelo o intermediário tem  a <br /> 
                                        liberdade de negociar livremente <br />
                                        o valor de compra da cota cancelada <br /> 
                                        respeitando os paramentos e limites de <br /> 
                                        proposta de nossa calculadora, correndo  por <br /> 
                                        sua conta e risco todas as despesas da <br />
                                        negociação.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='w-full h-52 flex p-3'>
                            <div>
                                <img src="about_settings.png" alt="logo" className='h-16 w-16' />
                            </div>
                            <div className='p-4 pt-0'>
                                <div className='font-bold'>
                                    <span>Disposições Gerais</span>
                                </div>
                                <div>
                                    <span className='text-sm'>
                                        As formas de intermediação são <br />
                                        independentes e não podem ser <br />
                                        misturadas na mesma negociação, ou <br />
                                        seja, para a mesma cota.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AboutIntermediation;