import React, { useState } from 'react';
import './ComponentContato.css';

function ComponentContato() {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [mensagem, setMensagem] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();    
  };

  return (
    <form onSubmit={handleSubmit} className='p-8 pt-5 rounded-lg font-sans shadow-2xl shadow-blue-500/50' style={{ width:'400px', height: '400px', backgroundColor: '#004e80' }}>
      <h3 className='text-center normal-case text-white font-sans mb-3 text-xl font-raleway font-medium'>Formulário</h3>
      <div className='mb-4'>
        <input type="text" className='w-full bg-white rounded-lg h-10 p-3' placeholder='Nome' value={nome} onChange={(event) => setNome(event.target.value)} required />
      </div>
      <div className='mb-4'>
        <input type="text" className='w-full bg-white rounded-lg h-10 p-3' placeholder='E-mail' value={email} onChange={(event) => setEmail(event.target.value)} required />
      </div>
      <div className='mb-2'>
        <textarea type="text" className='w-full bg-white rounded-lg h-36 p-3' placeholder='Mensagem' value={mensagem} onChange={(event) => setMensagem(event.target.value)} required />
      </div>
      <button type="submit" className='w-full text-white border-none rounded-lg py-2 px-4 cursor-pointer h-10 ' style={{ backgroundColor: '#c76f36' }}>Enviar</button>
    </form>
  );
}

export default ComponentContato;
