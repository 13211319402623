import React from 'react';
import './PPRDescription.css';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import 'react-toastify/dist/ReactToastify.css'

function PPRDescription() {
  return (
    <>
      <div className='flex space-x-6 mx-auto my-auto h-sm:hidden'>
        <div className="h-48 w-64 bg-white rounded-xl text-center flex flex-col justify-center items-center p-7 drop-shadow-sm">
          <img className="h-16" src="ppr_glass.png" alt="logo" />
          <span className="font-raleway">Transparência</span>
        </div>
        <div className="h-48 w-64 bg-white rounded-xl text-center flex flex-col justify-center items-center p-7 drop-shadow-sm">
          <img className="h-16" src="ppr_people.png" alt="logo" />
          <span className="font-raleway">Sigilo sobre a indicação</span>
        </div>
        <div className="h-48 w-64 bg-white rounded-xl text-center flex flex-col justify-center items-center p-7 drop-shadow-sm">
          <img className="h-16" src="ppr_doc.png" alt="logo" />
          <span className="font-raleway">Sem burocracia</span>
        </div>
        <div className="h-48 w-64 bg-white rounded-xl text-center flex flex-col justify-center items-center p-7 drop-shadow-sm">
          <img className="h-16" src="ppr_avatar.png" alt="logo" />
          <span className="font-raleway">Área restrita para acompanhamento</span>
        </div>
      </div>
      {/* Componente responsivo para celular */}
      <div className='w-full px-6 space-y-4 mt-10 h-sm:block hidden'>
        <div className='flex space-x-4 mx-auto my-auto w-full'>
          <div className="h-44 w-1/2 bg-white rounded-xl text-center flex flex-col justify-center items-center p-2 drop-shadow-sm">
            <img className="h-16" src="ppr_glass.png" alt="logo" />
            <span className="font-raleway">Transparência</span>
          </div>
          <div className="h-44 w-1/2 bg-white rounded-xl text-center flex flex-col justify-center items-center p-2 drop-shadow-sm">
            <img className="h-16" src="ppr_people.png" alt="logo" />
            <span className="font-raleway">Sigilo sobre a indicação</span>
          </div>
        </div>
        <div className='flex space-x-4 mx-auto my-auto'>
          <div className="h-44 w-1/2 bg-white rounded-xl text-center flex flex-col justify-center items-center p-2 drop-shadow-sm">
            <img className="h-16" src="ppr_doc.png" alt="logo" />
            <span className="font-raleway">Sem burocracia</span>
          </div>
          <div className="h-44 w-1/2 bg-white rounded-xl text-center flex flex-col justify-center items-center p-2 drop-shadow-sm">
            <img className="h-16" src="ppr_avatar.png" alt="logo" />
            <span className="font-raleway">Área restrita para acompanhamento</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default PPRDescription;