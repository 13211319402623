import React, { useRef, useState } from "react";
import "./VideoBackground.css";
import VideoPlayer from "react-background-video-player";
import video from "../../assets/calculadora.mp4"; 

const VideoBackground = () => {
  const videoPlayerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [volume, setVolume] = useState(0); // Volume varia de 0 a 1

  const handlePausePlay = () => {
    if (isPlaying) {
      videoPlayerRef.current.pause();
    } else {
      videoPlayerRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    videoPlayerRef.current.volume = newVolume; // Acessar diretamente o volume do vídeo
    if (newVolume > 0) {
      videoPlayerRef.current.muted = false;
    } else {
      videoPlayerRef.current.muted = true;
    }
    setVolume(newVolume);
  };

  return (
    <div>
      <VideoPlayer
        ref={videoPlayerRef}
        className="video shadow-2xl"
        src={video}
        autoPlay={true}
        muted={volume === 0}
        loop={false}
        style={{ top: -28 }}
        volume={volume}
      />
      <div className="controls z-10">
        <button onClick={handlePausePlay}>
          {isPlaying ? "||" : "▷"}
        </button>
        <input 
          type="range" 
          min="0" 
          max="1" 
          step="0.1" 
          value={volume} 
          onChange={handleVolumeChange} 
          className="w-20"
        />
      </div>
    </div>
  );
};

export default VideoBackground;