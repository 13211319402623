import React, { useState, useEffect } from 'react';
import { getCookie, hasCookie } from 'cookies-next';
import jwtDecode from 'jwt-decode';
import { showMainPpr, exit, recommendation, profile } from '../PPR/utilsPPR';
import { getLeadsById } from '../../utils/api';
import EditRecommendationPPR from '../../components/EditRecommendationPPR/EditRecommendationPPR';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset, faEye, faPenToSquare, faUser, faPlus } from '@fortawesome/free-solid-svg-icons';
import ChatPPR from '../../components/ChatPPR/ChatPPR';
import DetailsPPR from '../DetailsPPR/DetailsPPR';
import AddRecommendationPPR from '../AddRecommendationPPR/AddRecommendationPPR';
import ProfilePPR from '../ProfilePPR/ProfilePPR';

import './PPR.css';

export default function PPR() {
    const [data, setData] = useState([]);
    const [referrerUserName, setReferrerUserName] = useState('');
    const [loading, setLoading] = useState(true);
    const [leadModals, setLeadModals] = useState({});
    const [chatModalOpen, setChatModalOpen] = useState(false);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [modalPPROpen, setModalPPROpen] = useState(false);
    const [modalProfilePPR, setModalProfilePPR] = useState(false);

    const openChatModal = (leadId) => {
        setChatModalOpen((prevModals) => ({
            ...prevModals,
            [leadId]: true,
        }));
    };

    const closeChatModal = () => {
        setChatModalOpen(false);
    };

    const openDetailsModal = (leadId) => {
        setDetailsOpen((prevModals) => ({
            ...prevModals,
            [leadId]: true,
        }));
    };

    const closeDetailsModal = () => {
        setDetailsOpen(false);
    };

    const openModal = (leadId) => {
        setLeadModals((prevModals) => ({
            ...prevModals,
            [leadId]: true,
        }));
    };

    const closeModal = (leadId) => {
        setLeadModals((prevModals) => ({
            ...prevModals,
            [leadId]: false,
        }));
    };

    const openModalRegisterPPR = () => {
        setModalPPROpen(true);
    };

    const closeModalRegisterPPR = () => {
        setModalPPROpen(false);
    };

    const openModalProfilePPR = () => {
        setModalProfilePPR(true);
    };

    const closeModalProfilePPR = () => {
        setModalProfilePPR(false);
    };

    useEffect(() => {
        async function fetchData() {
            if (!hasCookie('token')) {
                return;
            }

            try {
                const token = getCookie('token');
                const decodedToken = jwtDecode(token);

                if (decodedToken && decodedToken.referrerUser) {
                    setReferrerUserName(decodedToken.referrerUser.name);
                    showMainPpr();
                }
                setLoading(false);

                const leads = await getLeadsById(decodedToken.referrerUser.id);

                if (leads.length === 0) {
                    switchLead();
                } else {
                    if (leads) {
                        setData(leads);
                        setLoading(false);
                    } else {
                        setLoading(false);
                    }
                }

            } catch (error) {
                setLoading(false);
            }
        }
        fetchData();
    }, []);

    const switchLead = () => {
        document.getElementById("table-leads").classList.add("hide");
        document.getElementById("btn-recommendation").classList.add("hide");
        document.getElementById("first-recommendation").classList.remove("hide");

    };

    return (
        <div className='h-lg:pb-32 hide h-sm:z-10 h-sm:pt-9 h-sm:pb-9' id='ppr'>
            <div className='bg-white mx-16 my-8 rounded shadow-2xl px-8 h-sm:mx-2 h-sm:px-2' style={{ height: "360px" }}>
                <div className='w-full h-20 flex'>
                    <div className='mt-2 inline-block flex items-center w-1/2 h-sm:mt-0'>
                        <h2 className='h-sm:hidden'>Bem Vindo, {referrerUserName}</h2>
                        <button className='ml-2 w-[173px] h-10 bg-[#c76f36] border-0 rounded-md mt-1 text-white cursor-pointer' onClick={openModalRegisterPPR} id='btn-recommendation'>Fazer indicação <FontAwesomeIcon icon={faPlus} /></button>
                        <AddRecommendationPPR isOpen={modalPPROpen} closeModal={closeModalRegisterPPR} />
                    </div>
                    <div className='mt-2 inline-block flex items-center justify-end w-1/2 space-x-3 h-sm:mt-0'>
                        <button className='bg-[#004e80] border-0 rounded-md mt-1 text-white cursor-pointer w-20 h-10' onClick={openModalProfilePPR}>Perfil <FontAwesomeIcon icon={faUser} /></button>
                        <ProfilePPR isOpen={modalProfilePPR} closeModal={closeModalProfilePPR} />
                        <span className='cursor-pointer text-red-500 font-semibold' onClick={exit}>SAIR</span>
                    </div>
                </div>
                <div className='w-full bg-white h-64' id='table-leads'>
                    <table className='text-base table-auto w-full' rules="none">
                        <thead>
                            <tr className='text-left w-full bg-slate-200'>
                                <th className='w-2/12'>Nome</th>
                                <th className='w-1/12 h-sm:hidden'>Telefone</th>
                                <th className='w-1/12 h-sm:hidden'>Valor</th>
                                <th className='w-1/12 h-sm:hidden'>% pago</th>
                                <th className='w-1/12 h-sm:hidden'>Encerramento</th>
                                <th className='w-1/12 h-sm:hidden'>Status</th>
                                <th className='w-1/12'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((lead, index) => (
                                <tr key={index}>
                                    <td className='text-left'>
                                        <span className='h-sm:hidden'>
                                            {lead.name}
                                        </span>
                                        <span className='h-sm:block hidden'>
                                            {lead.name.length > 15 ? lead.name.substring(0, 15) + '...' : lead.name}
                                        </span>
                                    </td>
                                    <td className='h-sm:hidden' >{lead.phonenumber}</td>
                                    <td className='h-sm:hidden' >R$ {lead.creditValue}</td>
                                    <td className='h-sm:hidden'>{lead.percentValue}%</td>
                                    <td className='h-sm:hidden'>{lead.finalDate}</td>
                                    <td className='h-sm:hidden'>
                                        <span className={`text-sm ${lead.status === 'Em andamento' ? '-1' : ''}`}>{lead.status}</span>
                                    </td>
                                    <td className='flex justify-center items-center space-x-2'>
                                        <button className='w-[40px] h-[30px] rounded-md cursor-pointer border-0 bg-[#e0ab0b] text-white' onClick={() => openDetailsModal(lead.id)}><FontAwesomeIcon icon={faEye} /></button>
                                        <DetailsPPR isOpen={detailsOpen[lead.id]} onRequestClose={closeDetailsModal} lead={lead} />

                                        <button onClick={() => openModal(lead.id)} className='w-[40px] h-[30px] rounded-md cursor-pointer border-0 bg-[#c76f36] text-white' style={{ margin: "0" }}><FontAwesomeIcon icon={faPenToSquare} /></button>
                                        <EditRecommendationPPR isOpen={leadModals[lead.id]} onRequestClose={() => closeModal(lead.id)} lead={lead} />

                                        {
                                            lead.idconsultant ?
                                                <button className='w-[40px] h-[30px] rounded-md cursor-pointer border-0 bg-green-700 text-white' onClick={() => openChatModal(lead.id)}><FontAwesomeIcon icon={faHeadset} /></button>
                                                :
                                                <button className='w-[40px] h-[30px] rounded-md cursor-pointer border-0 bg-white'>-</button>
                                        }

                                        <ChatPPR isOpen={chatModalOpen[lead.id]} onRequestClose={closeChatModal} lead={lead} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div id='first-recommendation' className='hide'>
                    <h3 className='first-recommendation-title'>Ainda não possui nenhuma indicação...</h3>
                    <br />
                    <h1 className='first-recommendation-subtitle'>Faça sua primeira indicação</h1>
                    <button className='btn-first-add' onClick={recommendation} id='btn-first-recommendation'>INDICAR</button>
                </div>
            </div>

        </div>
    );
}

