import React from "react";
import Calculadora from "../../components/CalculadoraV2/CalculadoraV2";
 
import "./CalculadoraV2.css";

function CalculadoraV2() {
  return (
    <section className="sectionHome">         
      <div className="main-content">        
        <div className="area-calculadora"> 
          <div className="div-calculadora">
            <Calculadora />            
          </div>
        </div>         
      </div>
      
    </section>        
  );
}

export default CalculadoraV2;
