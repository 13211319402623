import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { getCookie, hasCookie } from 'cookies-next';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';
import { showPPR } from '../PPR/utilsPPR'
import './DataBankPPR.css';
import InputMask from 'react-input-mask';
import { editDataBank } from '../../utils/api';

Modal.setAppElement('#root'); // Define o elemento raiz para acessibilidade
// { isOpen, closeModal: closeModalProp }

function DataBankPPR({ isOpen, closeModal: closeModalProp }) {
  const [bank, setBank] = useState('');
  const [agency, setAgency] = useState('');
  const [account, setAccount] = useState('');
  const [digit, setDigit] = useState('');
  const [pixType, setPixType] = useState('');
  const [pixValue, setPixValue] = useState('');
  const [cpf, setCpf] = useState('');
  const [idUser, setIdUser] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [placeholder, setPlaceholder] = useState('');
  const [mask, setMask] = useState('');

  const handleChange = (e) => {
    setPixType(e.target.value);
    maskInput(e.target.value);
  };

  const maskInput = (value) => {
    if (value === 'CPF') {
      setPixValue('');
      setPlaceholder('000.000.000-00');
      setMask('999.999.999-99');
    } else if (value === 'Telefone') {
      setPixValue('');
      setPlaceholder('(00) 00000-0000');
      setMask('(99) 99999-9999');
    } else if (value === 'Email') {
      setPixValue('');
      setPlaceholder('');
      setMask('');
    } else if (value === '') {
      setPixValue('');
      setPlaceholder('');
      setMask('');
    }
  };

  const setUserData = (userData) => {
    setBank(userData.bank);
    setAgency(userData.agency_number);
    setAccount(userData.account_number);
    setDigit(userData.digit_account);
    setPixType(userData.pix_type);
    setPixValue(userData.pix_value);
    setCpf(userData.cpf);
    setIdUser(userData.id);
  };

  var decodedToken = "";

  useEffect(() => {
    async function fetchData() {
      if (!hasCookie('token')) {
        return;
      }
      const token = getCookie('token');
      decodedToken = jwtDecode(token);
      setUserData(decodedToken.referrerUser);
    }
    fetchData();
  }, []);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    closeModalProp();
  };

  const editBank = async (event) => {
    event.preventDefault();

    var data = {
      bank: bank,
      agency: agency,
      account: account,
      digit: digit,
      pixType: pixType,
      pixValue: pixValue,
      cpf: cpf,
      bankEdit: 1,
    };

    const id = toast.loading('Aguarde...', { duration: null });

    try {
      const response = await editDataBank(idUser, data);

      if (response) {
        if (response.status === 409) {
          toast.update(id, { render: 'Os dados permanecem idênticos aos já cadastrados!', type: 'info', isLoading: false, autoClose: 2500 });
          console.log("Conflito de dados (status 409)");
        } else if (response.status === 200 || response.status === 201) {
          toast.update(id, { render: 'Alteração realizada com sucesso!', type: 'success', isLoading: false, autoClose: 2500 });

          setTimeout(function () {
            closeModal();
          }, 2500);
        }
      } else {
        console.log("Erro na resposta da API");
      }
    } catch (err) {
      console.log("Erro na requisição:", err);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModalProp}
      contentLabel="Exemplo de Modal"
      className='absolute inset-10 h-sm:inset-0 border-gray-300 bg-white overflow-auto outline-none p-5 rounded-2xl w-1/3 h-sm:w-full mx-auto mt-20'
      shouldCloseOnOverlayClick={true}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000,
        },
        content: {
          zIndex: 1001,
          height: '400px',
          backgroundColor: '#004e80'
        },
      }}
    >
      <div className='w-full h-auto flex justify-end'>
        <button className='rounded-full h-9 w-9 text-white font-bold' onClick={closeModal} style={{ border: '4px solid white' }}>X</button>
      </div>
      <h1 className='text-2xl text-center m-0 text-white'>Dados Bancários</h1>
      <div className='px-10 space-y-4 h-sm:px-5'>
        <InputMask type="text" mask="999.999.999-99" className='w-full bg-slate-200 rounded-lg h-10 p-3 font-normal' placeholder='CPF' value={cpf} onChange={e => setCpf(e.target.value)} />
        <input type="text" className='w-full bg-slate-200 rounded-lg h-10 p-3 font-normal' placeholder='Banco' value={bank} onChange={e => setBank(e.target.value)} />
        <div className='w-full flex space-x-2'>
          <input type="text" className='w-2/6 bg-slate-200 rounded-lg h-10 p-3 font-normal' maxLength={4} placeholder='Agência' value={agency} onChange={e => setAgency(e.target.value)} />
          <input type="text" className='w-3/6 bg-slate-200 rounded-lg h-10 p-3 font-normal' maxLength={5} placeholder='Conta' value={account} onChange={e => setAccount(e.target.value)} />
          <span>-</span>
          <input type="text" className='w-1/6 bg-slate-200 rounded-lg h-10 p-3 font-normal' maxLength={1} placeholder='Digito' value={digit} onChange={e => setDigit(e.target.value)} />
        </div>
        <div>
          <span className='text-white'>Pix</span>
          <div className='space-x-2 flex'>
            <select className='w-1/4 bg-slate-200 rounded-lg h-10 p-3 font-normal' value={pixType} onChange={handleChange} placeholder='Tipo Pix'>
              <option></option>
              <option>Email</option>
              <option>Telefone</option>
              <option>CPF</option>
            </select>
            <InputMask
              type="text"
              mask={mask}
              placeholder={placeholder}
              className='w-3/4 bg-slate-200 rounded-lg h-10 p-3 font-normal'
              value={pixValue}
              onChange={(e) => setPixValue(e.target.value)}
              onBlur={(e) => maskInput(e.target.value)}
            />
          </div>
        </div>
        <button className='text-white rounded-lg border-0 w-full h-10 text-base cursor-pointer' style={{ backgroundColor: '#c76f36' }} onClick={editBank}>Salvar</button>
      </div>
    </Modal>
  );
};

export default DataBankPPR;
