import {deleteCookie} from 'cookies-next';

export function showRegister() {
    document.getElementById("login").classList.add("hide");
    document.getElementById("register").classList.remove("hide");
}

export function showLogin(event) {
    //event.preventDefault();
    document.getElementById("register").classList.add("hide");
    document.getElementById("login").classList.remove("hide");
}

export function showMainPpr(){
    document.getElementById("login").classList.add("hide");
    document.getElementById("ppr").classList.remove("hide");
  }

  export function forgotPasswordScreen (event)  {
    event.preventDefault();
    document.getElementById("login").classList.add("hide");
    document.getElementById("password").classList.remove("hide");
  }

export function about (event) {
    event.preventDefault();
    document.getElementById("login").classList.add("hide");
    document.getElementById("about").classList.remove("hide");
  }

  export function exit  (event) {
    deleteCookie('token', {path: '/'});
    
    document.getElementById("ppr").classList.add("hide");
    document.getElementById("login").classList.remove("hide");

    event.preventDefault();        
}

export function recommendation (event) {
    event.preventDefault();

    document.getElementById("ppr").classList.add("hide");
    document.getElementById("recommendation").classList.remove("hide");
}

export function profile (event) {
    event.preventDefault();

    document.getElementById("ppr").classList.add("hide");
    document.getElementById("profile").classList.remove("hide");
}

export function showPPR (event) {
  window.location.reload();
  document.getElementById("profile").classList.add("hide");  
  document.getElementById("ppr").classList.remove("hide");
}

export function cancelProfile (event) {
  event.preventDefault();
  document.getElementById("profile").classList.add("hide");
  document.getElementById("ppr").classList.remove("hide");
}

export const toLogin = () => {
  document.getElementById("password").classList.add("hide");
  document.getElementById("login").classList.remove("hide");
}

export function recommendationEdit () {
  document.getElementById("ppr").classList.add("hide");
  document.getElementById("recommendation").classList.remove("hide");

}
export function aboutCancel (event) {
  event.preventDefault();

  document.getElementById("about").classList.add("hide");
  document.getElementById("login").classList.remove("hide");
}