const API_URL_LOCAL = 'http://localhost:3000/api';
const API_URL_PROD = 'https://admin-dashboard-template-liard.vercel.app/api';

const API_URL = API_URL_PROD;

export async function loginppr(data){
    const response = await fetch(API_URL + '/loginppr', {
        method: 'POST',        
        body: JSON.stringify(data)
    });

    return response; 
}

export async function getLeadsById(idReferrer){
    const response = await fetch(API_URL + '/leads/' + idReferrer);
          
    return response.json();    
}

export async function getLeadsByIdEdit(idReferrer, idLead){
    const response = await fetch(API_URL + '/leads/' + idReferrer+'?idLead='+idLead);
          
    return response.json();    
}

export async function editLeadsByIdEdit(idLead, data) {
    console.log('editLeadsByIdEdit',data)
    try {
        const response = await fetch(`${API_URL}/leads/${idLead}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`Erro ao editar referrer: ${response.status} - ${response.statusText} - ${await response.text()}`);
        }

        const responseData = await response.json();
        console.log(responseData)
        return responseData;
    } catch (error) {
        console.error('Erro na requisição:', error);
        throw new Error(`Erro ao editar referrer: ${error.message}`);
    }
}

export async function newReferrer(data){
    const response = await fetch(API_URL + '/referrers', {
        method: 'POST',        
        body: JSON.stringify(data)
    });
    
    return response; 
}

export async function newLead(data, idReferrer){
    const response = await fetch(API_URL + '/leads/'+ idReferrer, {
        method: 'POST',        
        body: JSON.stringify(data)
    });
    
    return response.json(); 
}

export async function getReferrersById(idReferrer){
    const response = await fetch(API_URL + '/referrers/' + idReferrer);
    
    const responseData = await response.json();
    return responseData;    
}

export async function editReferrerById(referrerId, data) {
    try {
        const response = await fetch(`${API_URL}/referrers/${referrerId}`, {
            method: 'PUT', // ou 'PATCH' dependendo do seu caso
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`Erro ao editar referrer: ${response.status} - ${response.statusText}`);
        }

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Erro na requisição:', error);
        throw error; // Você pode optar por tratar ou relançar o erro, dependendo do seu caso de uso.
    }
}

export async function getChatByLeadId(idLead){
    const response = await fetch(API_URL + '/chat/' + idLead);
    
    const responseData = await response.json();
    return responseData;    
}

export async function newChat(data, idLead){
    const response = await fetch(API_URL + '/chat/'+ idLead, {
        method: 'POST',        
        body: JSON.stringify(data)
    });
    
    return response.json(); 
}

export async function editDataBank(referrerId, data) {
    try {
        const response = await fetch(`${API_URL}/referrers/${referrerId}`, {
            method: 'PUT', // ou 'PATCH' dependendo do seu caso
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`Erro ao editar referrer: ${response.status} - ${response.statusText}`);
        }

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Erro na requisição:', error);
        throw error; // Você pode optar por tratar ou relançar o erro, dependendo do seu caso de uso.
    }
}