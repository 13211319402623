import React, { useState } from 'react';
import './HomeDepositions.css';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import 'react-toastify/dist/ReactToastify.css'

function HomeDepositions() {

    const testimonials = [
        {
            name: "Marcelo Willian Silva",
            rating: "⭐️⭐️⭐️⭐️⭐️",
            testimonial: "“Empresa séria de confiança, Consultora Luana está de parabéns, excelente atendimento, estava com muita dificuldade na venda da minha carta, eles resolveram com muita simplicidade e competência, super indico, confiança total, tudo registrado em cartório, novamente Consultora Luana tem meu respeito, fez muito por mim!”"
        },
        {
            name: "Renata Salvador",
            rating: "⭐️⭐️⭐️⭐️⭐️",
            testimonial: "“Recomendo, empresa muito confiável... David Santos me deu todo o suporte que precisei, sempre com educação e transparência... venda do meu consórcio realizado com sucesso✅✅ Agradecida.”"
        },
        {
            name: "Benedito Melo",
            rating: "⭐️⭐️⭐️⭐️⭐️",
            testimonial: "“Foi a melhor experiência que eu já tive como cliente, foi conduzido por uma colaboradora chamada Hanna Perreira.. que teve toda cordialidade, Paciência e o domínio no processo que me conduzia como nunca vi igual.. chegando ao Êxito e superou todas as minhas Expectativas.. Parabéns Hanna Pereira.”"
        }
    ];

    const [currentTestimonial, setCurrentTestimonial] = useState(0);

    const handleNext = () => {
        setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
    };

    const handlePrev = () => {
        setCurrentTestimonial((prev) => (prev - 1 + testimonials.length) % testimonials.length);
    };

    return (
        <>
            <div className='w-full flex space-x-6 mx-auto my-auto h-96 h-sm:hidden' style={{ height: '480px' }}>
                <div className='flex space-x-4 mx-auto my-auto'>
                    <div className='h-96' style={{ width: '1000px', height: '480px' }}>
                        <div className='text-center'>
                            <div>
                                <span style={{ color: '#f5be88' }}>Depoimentos</span>
                            </div>
                            <div className='mt-4'>
                                <span className='text-2xl font-extrabold font-raleway'>O quê as pessoas dizem sobre nós</span>
                            </div>
                        </div>
                        <div className='w-full mt-5 mx-auto flex space-x-5 h-96'>
                            <div className='bg-white h-full w-1/3 rounded-md shadow-3xl flex'>
                                <div className='p-5 px-4 mt-5'>
                                    <div>
                                        <span className='text-base font-semibold'>Marcelo Willian Silva</span>
                                    </div>
                                    <div className='mt-2'>
                                        <span>⭐️⭐️⭐️⭐️⭐️</span>
                                    </div>
                                    <div className='mt-3'>
                                        <span className='text-base text-justify'>
                                            “Empresa séria de confiança, Consultora Luana está de parabéns,
                                            excelente atendimento, estava com muita dificuldade na
                                            venda da minha carta, eles resolveram com muita simplicidade
                                            e competência, super indico, confiança total, tudo registrado
                                            em cartório, novamente Consultora Luana tem meu respeito,
                                            fez muito por mim!”
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='bg-white h-full w-1/3 rounded-md shadow-3xl flex'>
                                <div className='p-5 px-4 mt-5'>
                                    <div>
                                        <span className='text-base font-semibold'>Renata Salvador</span>
                                    </div>
                                    <div className='mt-2'>
                                        <span>⭐️⭐️⭐️⭐️⭐️</span>
                                    </div>
                                    <div className='mt-3'>
                                        <span className='text-base text-justify'>
                                            “Recomendo, empresa muito confiável... David Santos me deu todo
                                            o suporte que precisei, sempre com educação e transparência...
                                            venda do meu consórcio realizado com sucesso✅✅ Agradecida.”
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='bg-white h-full w-1/3 rounded-md shadow-3xl flex'>
                                <div className='p-5 px-4 mt-5'>
                                    <div>
                                        <span className='text-base font-semibold'>Benedito Melo</span>
                                    </div>
                                    <div className='mt-2'>
                                        <span>⭐️⭐️⭐️⭐️⭐️</span>
                                    </div>
                                    <div className='mt-3'>
                                        <span className='text-base'>
                                            “Foi a melhor experiência que eu já tive como cliente, foi
                                            conduzido por uma colaboradora chamada Hanna Perreira..
                                            que teve toda cordialidade, Paciência e o domínio no processo
                                            que me conduzia como nuncá vi igual.. chegando ao Êxito e superou
                                            todas as minhas Expectativas.. Parabéns Hanna Pereira.”
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Componente responsivo para celular */}
            <div className='w-full flex space-x-6 mx-auto my-auto h-sm:flex hidden px-4' style={{ height: '480px' }}>
                <div className='flex space-x-4 mx-auto my-auto'>
                    <div className='w-full h-full' >
                        <div className='w-full'>
                            <div>
                                <span style={{ color: '#f5be88' }}>Depoimentos</span>
                            </div>
                            <div className='mt-4'>
                                <span className='text-xl font-extrabold font-raleway'>O quê as pessoas dizem sobre nós</span>
                            </div>
                        </div>
                        <div className='w-full mt-5 mx-auto flex items-center space-x-2 h-96'>
                            <div onClick={handlePrev} style={{ cursor: 'pointer' }}>
                                <img src="left.png" alt="logo" />
                            </div>
                            <div className='bg-white h-full w-full rounded-md shadow-3xl flex'>
                                <div className='p-5 px-4 mt-5'>
                                    <div>
                                        <span className='text-base font-semibold'>{testimonials[currentTestimonial].name}</span>
                                    </div>
                                    <div className='mt-2'>
                                        <span>{testimonials[currentTestimonial].rating}</span>
                                    </div>
                                    <div className='mt-3'>
                                        <span className='text-base text-justify'>
                                            {testimonials[currentTestimonial].testimonial}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div onClick={handleNext} style={{ cursor: 'pointer' }}>
                                <img src="right.png" alt="logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HomeDepositions;