import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link, NavLink } from 'react-router-dom';
import Home from '../../pages/Home/Home';
import Sobre from '../../pages/Sobre/Sobre';
import Servicos from '../../pages/Servicos/Servicos';
import Contato from '../../pages/Contato/Contato';
import ParticipacaoRemunerada from '../../pages/ParticipacaoRemunerada/ParticipacaoRemunerada';
import CalculadoraV2 from '../../pages/CalculadoraV2/CalculadoraV2';
import CalculadoraV1 from '../../pages/CalculadoraV1/CalculadoraV1';
import './Navbar.css';
import '../../styles.css'

function Navbar() {

  return (
    <Router>
      <nav className='fixed top-0 left-0 w-screen h-22 px-8 py-2 text-base bg-white z-50 shadow-xl'>
        <div className="flex">
          <div className="w-2/12 flex justify-center items-center">
            <Link to="/">
              <img className="h-16 h-sm:h-10" src="logo-objetiva2.png" alt='logo' />
            </Link>
          </div>
          <div className="w-8/12 h-sm:hidden">
            <ul className="flex justify-center items-center list-none h-20 font-raleway">
              <li className="w-1/12 text-center">
                <NavLink to="/" className={({ isActive }) => isActive ? "text-orange-500" : ""}>Home</NavLink>
              </li>
              <div className="w-px h-8 bg-indigo-700"></div>
              <li className="w-2/12 text-center">
                <NavLink to="/sobre" className={({ isActive }) => isActive ? "text-orange-500" : ""}>Quem somos</NavLink>
              </li>
              <div className="w-px h-8 bg-indigo-700"></div>
              <li className="w-3/12 text-center">
                <NavLink to="/participacaoRemunerada" className={({ isActive }) => isActive ? "text-orange-500" : ""}>Programa de parceria remunerada</NavLink>
              </li>
              <div className="w-px h-8 bg-indigo-700"></div>
              <li className="w-3/12 text-center">
                <NavLink to="/servicos" className={({ isActive }) => isActive ? "text-orange-500" : ""}>Intermediação de cotas canceladas</NavLink>
              </li>
              <div className="w-px h-8 bg-indigo-700"></div>
              <li className="w-2/12 text-center">
                <NavLink to="/contato" className={({ isActive }) => isActive ? "text-orange-500" : ""}>Contato</NavLink>
              </li>
              <div className="w-px h-8 bg-indigo-700"></div>
              <li className="w-1/12 text-center">
                <a href="https://objetivascp.com.br/login" target="_blank" rel="noreferrer">SCP</a>
              </li>
            </ul>
          </div>
          <div className="hidden h-sm:w-10/12 h-sm:block">
            <ul className="flex justify-center items-center list-none h-20 font-raleway">
              <li className="w-1/6 text-center flex justify-center items-center"><Link to="/"><img src="home.png" alt="logo" /></Link></li>
              <li className="w-1/6 text-center flex justify-center items-center"><Link to="/sobre"><img src="company.png" alt="logo" /></Link></li>
              <li className="w-1/6 text-center flex justify-center items-center"><Link to="/participacaoRemunerada"><img src="hands.png" alt="logo" /></Link></li>
              <li className="w-1/6 text-center flex justify-center items-center"><Link to="/servicos"><img src="people-icon.png" alt="logo" /></Link></li>
              <li className="w-1/6 text-center flex justify-center items-center"><Link to="/contato"><img src="contacts.png" alt="logo" /></Link></li>
              <li className="w-1/6 text-center flex justify-center items-center"><a href="https://objetivascp.com.br/login" target="_blank" rel="noreferrer"><img src="scp.png" alt="logo" /></a></li>
            </ul>
          </div>
          <div className="w-2/12 flex justify-center items-center h-sm:hidden">
            <div className="relative">
              <input className="bg-slate-50 rounded-full" type="text" name="" id="" />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <img className="" src="magnifier.png" alt='logo' />
              </div>
            </div>
          </div>
        </div>
      </nav>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/participacaoRemunerada" element={<ParticipacaoRemunerada />} />
        <Route path="/sobre" element={<Sobre />} />
        <Route path="/servicos" element={<Servicos />} />
        <Route path="/contato" element={<Contato />} />
        <Route path="/calculadoraV2" element={<CalculadoraV2 />} />
        <Route path="/calculadoraV1" element={<CalculadoraV1 />} />
      </Routes>
    </Router>
  );
}

export default Navbar;
