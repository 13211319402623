import React, { useState } from 'react';
import './AddRecommendationPPR.css';
import InputMask from 'react-input-mask';
import jwtDecode from 'jwt-decode';
import { getCookie } from 'cookies-next';
import { newLead, editLeadsByIdEdit } from '../../utils/api';
import numeral from 'numeral';
import { notify, notifySuccess, notifyPassword, notifySuccessEdit } from '../../utils/notify';
import { getLeadsByIdEdit } from '../../utils/api';
import { NumericFormat } from 'react-number-format';
import { toast } from 'react-toastify';
import Modal from 'react-modal';

function AddRecommendationPPR({ isOpen, closeModal }) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [phone2, setPhone2] = useState('');
    const [val, setVal] = useState('');
    const [percentage, setPercentage] = useState('');
    const [data, setData] = useState('');
    const [leadId, setLeadId] = useState('');

    Modal.setAppElement('#root');

    function clearInput() {
        setName('');
        setEmail('');
        setPhone('');
        setPhone2('');
        setVal('');
        setPercentage('');
        setData('');
    }

    const clearId = () => {
        const dadosParaArmazenar = "";
        localStorage.setItem('lead', JSON.stringify(dadosParaArmazenar));
    }

    const cancel = (event) => {
        event.preventDefault();
        clearInput();
        document.getElementById("recommendation").classList.add("hide");
        document.getElementById("ppr").classList.remove("hide");
        clearId();
    }

    const checksInput = () => {
        var input = "";
        if (name === '') {
            input = "nome";
            notify(input);
            return false;
        } else if (phone === '') {
            input = "telefone";
            notify(input);
            return false;
        } else if (email !== '') {
            const charactersEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!charactersEmail.test(email)) {
                input = "Por favor, insira um endereço de e-mail válido.";
                notifyPassword(input);
                return false;
            }
        }
        return true;
    }

    async function addRecommendation() {
        const token = getCookie('token');
        const decodedToken = jwtDecode(token);
        var value = document.getElementById("value_recommenddation").value;

        if (!checksInput()) {
            return false;
        }

        var status = "AGUARDANDO";
        let _data = {
            name: name,
            email: email,
            phone: phone,
            phone2: phone2,
            status: status,
            creditValue: value,
            percentValue: percentage,
            finalDate: data,
        }

        // Mostrar a mensagem de "Aguarde" no início
        const promise = new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, 1000);
        });

        toast.promise(
            promise,
            {
                pending: 'Aguarde...',
                success: 'Cadastro realizado com sucesso!',
                error: 'Erro ao cadastrar'
            }
        );

        const response = await newLead(_data, decodedToken.referrerUser.id);

        if (response && response.success) {
            toast.success('Cadastro realizado com sucesso!');
        }

        clearInput();

        setTimeout(function () {
            window.location.reload();
        }, 2500);
    }

    const addOrEdit = () => {
        if (leadId === '') {
            addRecommendation();
        } else {
            //editRecommendation();
        }
    }

    const showPPR = () => {
        document.getElementById("recommendation").classList.add("hide");
        document.getElementById("ppr").classList.remove("hide");
        window.location.reload();
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="Register PPR Modal"
            className='absolute inset-10 h-sm:inset-0 border-gray-300 bg-white overflow-auto outline-none p-5 rounded-2xl w-1/3 h-sm:w-full mx-auto mt-20'
            shouldCloseOnOverlayClick={true}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1000,
                },
                content: {
                    zIndex: 1001,
                    height: '370px',
                    backgroundColor: '#004e80'
                },
            }}
        >
            <div className='w-full h-auto flex justify-end'>
                <button className='rounded-full h-9 w-9 text-white font-bold' onClick={closeModal} style={{ border: '4px solid white' }}>X</button>
            </div>
            <div className='px-3'>
                <div className='w-full'>
                    <h1 className='text-2xl text-white text-center'>Dados do Indicado</h1>
                </div>
                <div className='w-full flex space-x-2 mt-5'>
                    <div className='w-1/2 space-y-4'>
                        <input type='text' value={name} onChange={e => setName(e.target.value)} placeholder='Nome' className='w-full bg-slate-200 rounded-lg h-10 p-3 font-normal' id='name_recommenddation' name='name_recommenddation'></input>
                        <div className='flex space-x-2'>
                            <div className='w-1/2'>
                                <InputMask utMask type='text' value={phone} onChange={e => setPhone(e.target.value)} mask="(99) 99999-9999" placeholder="Telefone" className='w-full bg-slate-200 rounded-lg h-10 p-3 font-normal' id='telefone_recommenddation' name='telefone_recommenddation'></InputMask><br />
                            </div>
                            <div className='w-1/2'>
                                <InputMask type='text' value={phone2} onChange={e => setPhone2(e.target.value)} mask="(99) 99999-9999" placeholder="Telefone 2" className='w-full bg-slate-200 rounded-lg h-10 p-3 font-normal' id='telefone_recommenddation2' name='telefone_recommenddation2'></InputMask><br />
                            </div>
                        </div>
                        <input type='text' value={email} onChange={e => setEmail(e.target.value)} placeholder='Email' className='w-full bg-slate-200 rounded-lg h-10 p-3 font-normal' id='mail_recommenddation' name='mail_recommenddation'></input>
                    </div>
                    <div className='w-1/2 space-y-4'>
                        <NumericFormat
                            value={val}
                            onChange={e => setVal((numeral(e).format('$0,0.00')).target)}
                            className='w-full bg-slate-200 rounded-lg h-10 p-3 font-normal' id='value_recommenddation'
                            name='value_recommenddation'
                            decimalSeparator=","
                            thousandSeparator="."
                            fixedDecimalScale={true}
                            decimalScale={2}
                            allowNegative={false}
                            placeholder='Valor do crédito'
                        />
                        <InputMask value={percentage} onChange={e => setPercentage(e.target.value)} placeholder='% pago ao fundo comum' className='w-full bg-slate-200 rounded-lg h-10 p-3 font-normal' id='percentage_recommenddation' name='percentage_recommenddation' mask="99.9999" maskChar={null} decimalSeparator="," thousandSeparator="," />
                        <InputMask value={data} onChange={e => setData(e.target.value)} type='text' mask="99/99/9999" placeholder="Data de encerramento" className='w-full bg-slate-200 rounded-lg h-10 p-3 font-normal' id='date_recommenddation' name='date_recommenddation'></InputMask>
                    </div>
                </div>

                <button className='w-full h-10 border-0 rounded mt-4 text-white cursor-pointer' style={{ backgroundColor: '#c76f36' }} onClick={addOrEdit}>Indicar</button>
            </div>

        </Modal>
    );
}

export default AddRecommendationPPR;
