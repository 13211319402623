import React, { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import calcularResultado from '../../utils/calculosV3';
import './Calculadora.css';
import LeadContactForm from '../LeadContactForm/LeadContactForm';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

function Calculadora() {
  const [credito, setCredito] = useState('');
  const [percentual, setPercentual] = useState('');
  const [dataEncerramento, setDataEncerramento] = useState('');
  const [isGirando, setIsGirando] = useState(false);
  const [resultado, setResultado] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showFailMessage, setShowFailMessage] = useState(false);

  const formatDate = (date) => {
    const d = new Date(date),
          day = '' + d.getDate(),
          month = '' + (d.getMonth() + 1),
          year = d.getFullYear();
  
    return [day.length < 2 ? '0' + day : day,
            month.length < 2 ? '0' + month : month,
            year].join('/');
  };

  const handleCalcular = (event) => {
    event.preventDefault();

    let resultadoCalculo = calcularResultado(credito, percentual, dataEncerramento);

    if (!credito || !percentual || !dataEncerramento) {
      setShowErrorMessage(true);
      setShowFailMessage(false);
      setResultado(null);
      setIsGirando(false);
      return;
    }else{
      if (!isNaN(resultadoCalculo)) {
        resultadoCalculo = parseFloat(resultadoCalculo);

        if(resultadoCalculo === 0){        
          setShowFailMessage(true);
          setShowErrorMessage(false);
          setResultado(null);
          setIsGirando(false);
          return;
        }else{
          const resultadoArredondado = parseFloat(resultadoCalculo).toFixed(2);
          setResultado(resultadoArredondado);
          setIsGirando(true); // Inicia o efeito de girar a calculadora
        }
        
      } else {
        setResultado(null);
        
      }
    }
  };

  const handleVoltar = () => {
    setIsGirando(false); // Define isGirando como falso para mostrar a frente (calculadora)
    setShowErrorMessage(false);
    setShowFailMessage(false); 
    setResultado(null); // Limpa o resultado para permitir um novo cálculo
    setCredito(''); // Limpa o campo de crédito
    setPercentual(''); // Limpa o campo de percentual
    setDataEncerramento(''); // Limpa o campo de data de encerramento
  };

  return (
    <div className='p-4 rounded-lg shadow-xl w-96 h-400 h-lg:h-450 h-lg:w-500 h-lg:p-7 h-sm:w-96' style={{ backgroundColor: '#c76f36' }}>
      {!isGirando ? (
        <div className="frente">
          <div className='flex justify-center space-x-8'>
            <div className='w-2 h-2 rounded-full' style={{ backgroundColor: '#004e80' }}></div>
            <div className='w-2 h-2 rounded-full' style={{ backgroundColor: '#f5be88' }}></div>
            <div className='w-2 h-2 rounded-full' style={{ backgroundColor: '#f5be88' }}></div>
          </div>

          <div className='h-12'>
            <h3 className='text-center normal-case text-blue-900 font-sans mb-1 mt-1 text-xl font-raleway' style={{ color: '#f5be88' }}>Nossa Proposta</h3>
            
            {showErrorMessage && 
              <div className='text-center'>
                <div className='text-xs text-white'>Por favor, preencha todos os campos corretamente!</div>
              </div>
            }
            {showFailMessage && 
              <div className='text-center'>
                <div className="text-xs text-white">Valores fora dos nossos parâmetros de proposta</div>
              </div>
            }
          </div>
          <form className='px-5'>
            <div className='mb-1'>
              <label className='text-white normal-case pl-0 pb-0 block font-normal font-raleway'>Crédito</label>
              <span className='text-white normal-case text-xs p-1 pl-0 pt-0 block font-normal font-raleway'>Valor do bem</span>
              <CurrencyInput
                intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}              
                decimalsLimit={2}
                groupSeparator="."
                //decimalSeparator=","
                value={credito}
                onValueChange={(value, name) => setCredito(value)}
                className='w-full p-1 bg-white rounded-lg h-8'
              />
            </div>
            <div className='mb-1'>       
              <label className='text-white normal-case pl-0 pb-0 block font-normal font-raleway'>Percentual Pago</label>
              <span className='text-white normal-case text-xs p-1 pl-0 pt-0 block font-normal font-raleway'>Valor pago em percentual do fundo comum</span>
              <CurrencyInput                         
                suffix="%"
                allowDecimals={true}              
                decimalSeparator=","
                groupSeparator="."
                allowNegativeValue={false}
                value={percentual}
                decimalScale={4}
                decimalsLimit={4}
                maxLength={7}
                fixedDecimalLength={4}   
                format="##,####"           
                onValueChange={(value, name) => setPercentual(value)}
                className='w-full p-1 bg-white rounded-lg h-8'
              />
            </div>
            <div className=''>  
              <label className='text-white normal-case pl-0 pb-0 block font-normal font-raleway'>Encerramento do Grupo</label>
              <span className='text-white normal-case text-xs p-1 pl-0 pt-0 block font-normal font-raleway'>Faltando até 10 anos para encerrar o grupo</span>
              <input 
                type="date" 
                value={dataEncerramento} 
                onChange={(event) => setDataEncerramento(event.target.value)} 
                className='w-full p-1 bg-white rounded-lg h-8'
              />
            </div>
            <button 
              className='mt-3 h-10 w-full rounded-lg text-sm border-none text-white cursor-pointer'
              style={{ backgroundColor: '#004e80' }} 
              type="submit" 
              onClick={handleCalcular}>
                Calcular
            </button>
          </form>
        </div>
      ) : (
        <div>          
          <div>
            <div className='flex justify-center items-center space-x-8 relative'>
              <div className='w-2 h-2 rounded-full' style={{ backgroundColor: '#f5be88' }}></div>
              <div className='w-2 h-2 rounded-full' style={{ backgroundColor: '#004e80' }}></div>
              <div className='w-2 h-2 rounded-full' style={{ backgroundColor: '#f5be88' }}></div>
              <div className='absolute right-0'>
                <button 
                  style={{ backgroundColor: '#004e80' }} 
                  className='text-white text-xs h-7 w-8 flex items-center justify-center text-center mt-0 rounded-full' 
                  onClick={handleVoltar}
                >
                  ↩
                </button>
              </div>
            </div>
            <h3 className='text-center normal-case text-blue-900 font-sans mb-1 mt-1 text-xl font-raleway' style={{ color: '#f5be88' }}>Confira nossa proposta</h3>
            <p className="text-3xl font-bold text-white mt-0 mb-1 text-center">{resultado ? parseFloat(resultado).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '0,00'}</p>  
            <div className='justify-center flex'>
              <div className='w-52 mt-4'>  
                <p className='text-xs text-white mt-0 mb-0 p-0'>Data da proposta: {formatDate(new Date())}</p>  
                <p className='text-xs text-white mt-0 mb-0 p-0'>Crédito: {credito ? parseFloat(credito).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '0,00'}</p> 
                <p className='text-xs text-white mt-0 mb-0 p-0'> Percentual pago: {percentual ? percentual: 0}%</p>
                <p className='text-xs text-white mt-0 mb-0 p-0'>Encerramento do grupo: {dataEncerramento ? format(parseISO(dataEncerramento), 'dd/MM/yyyy', {locale: ptBR}) : '00/00/0000'}</p>                  
              </div>
            </div>
          </div>
          <LeadContactForm /> 
        </div>
      )}
    </div>
  );
}

export default Calculadora;
