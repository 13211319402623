import React, { useState } from 'react';
import './HomeCommitment.css';
import 'react-toastify/dist/ReactToastify.css';

function HomeCommitment() {

    const cards = [
        {
            image: "work1.png",
            icon: "workflow.png",
            title: "Processo simples e ágil",
            description: "Nossa equipe especializada cuidará de todo o processo de venda do seu consórcio, tornando-o rápido e sem complicações. Você não terá que lidar com burocracias demoradas ou etapas complexas."
        },
        {
            image: "work2.png",
            icon: "security.png",
            title: "Segurança de seus dados",
            description: "Nosso compromisso com a sua privacidade é fundamental. Todas as informações fornecidas serão tratadas com a máxima confidencialidade e utilizadas apenas para fins relacionados à venda do seu consórcio."
        },
        {
            image: "work3.png",
            icon: "people.png",
            title: "Avaliação justa e transparente",
            description: "Faremos uma avaliação criteriosa do seu consórcio para oferecer uma oferta justa e transparente. Levamos em consideração todos os aspectos do seu consórcio, como o valor investido até o momento e prazo restante."
        }
    ];

    const [currentCard, setCurrentCard] = useState(0);

    const handleNext = () => {
        setCurrentCard((prevCard) => (prevCard + 1) % cards.length);
    };

    const handlePrev = () => {
        setCurrentCard((prevCard) => (prevCard - 1 + cards.length) % cards.length);
    };

    return (
        <>
            <div className='w-full flex space-x-6 mx-auto my-auto h-sm:hidden' style={{ height: '730px', backgroundColor: '#fff7ee' }}>
                <div className='flex space-x-6 mx-auto my-auto'>
                    <div style={{ width: '1150px', height: '730px' }}>
                        <div className='text-center'>
                            <div className='mt-8'>
                                <span style={{ color: '#f5be88' }}>Nossos compromissos</span>
                            </div>
                            <div className='mt-4'>
                                <span className='text-2xl font-extrabold font-raleway'>Garantia de Transparência e Segurança</span>
                            </div>
                            <div className='mt-4'>
                                <span className='text-xl font-bold font-raleway'>Vender seu consórcio pode gerar algumas preocupações e dúvidas.</span>
                            </div>
                            <div className='mt-4'>
                                <span className='text-sm'>
                                    Na Objetiva Consórcio, valorizamos a transparência e a segurança em todas as nossas  <br />
                                    transações. Queremos que você se sinta confiante em tomar essa decisão.
                                </span>
                            </div>
                        </div>
                        <div className='h-96 w-full mt-5 mx-auto flex space-x-14'>
                            <div className='bg-white h-full w-1/3 rounded-md drop-shadow-sm'>
                                <div className='relative'>
                                    <img src="work1.png" alt="logo" className='w-full h-36' />
                                    <div className='absolute top-24 left-0 w-full flex justify-center' style={{ zIndex: 1 }}>
                                        <div className='w-full flex justify-center'>
                                            <div className='w-1/2'></div>
                                            <div className='w-1/2 flex justify-center items-center'>
                                                <img src="workflow.png" alt="logo" className='w-20 h-20' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='p-4 mt-5'>
                                    <div>
                                        <span className='text-base font-semibold'>Processo simples e ágil</span>
                                    </div>
                                    <div className='mt-3'>
                                        <span className='text-base'>
                                            Nossa equipe especializada cuidará de todo o
                                            processo de venda do seu consórcio, tornando-o
                                            rápido e sem complicações. Você não terá
                                            que lidar com burocracias demoradas ou etapas
                                            complexas.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='bg-white h-full w-1/3 rounded-md drop-shadow-sm'>
                                <div className='relative'>
                                    <img src="work2.png" alt="logo" className='w-full h-36' />
                                    <div className='absolute top-24 left-0 w-full flex justify-center' style={{ zIndex: 1 }}>
                                        <div className='w-full flex justify-center'>
                                            <div className='w-1/2'></div>
                                            <div className='w-1/2 flex justify-center items-center'>
                                                <img src="security.png" alt="logo" className='w-20 h-20' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='p-4 mt-5'>
                                    <div>
                                        <span className='text-base font-semibold'>Segurança de seus dados</span>
                                    </div>
                                    <div className='mt-3'>
                                        <span className='text-base'>
                                            Nosso compromisso com a sua privacidade é
                                            fundamental. Todas as informações fornecidas
                                            serão tratadas com a máxima confidencialidade
                                            e utilizadas apenas para fins relacionados à
                                            venda do seu consórcio.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='bg-white h-full w-1/3 rounded-md drop-shadow-sm'>
                                <div className='relative'>
                                    <img src="work3.png" alt="logo" className='w-full h-36' />
                                    <div className='absolute top-24 left-0 w-full flex justify-center' style={{ zIndex: 1 }}>
                                        <div className='w-full flex justify-center'>
                                            <div className='w-1/2'></div>
                                            <div className='w-1/2 flex justify-center items-center'>
                                                <img src="people.png" alt="logo" className='w-20 h-20' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='p-4 mt-5'>
                                    <div>
                                        <span className='text-base font-semibold'>Avaliação justa e transparente</span>
                                    </div>
                                    <div className='mt-3'>
                                        <span className='text-base'>
                                            Faremos uma avaliação criteriosa do seu
                                            consórcio para oferecer uma oferta justa e
                                            transparente. Levamos em consideração todos
                                            os aspectos do seu consórcio, como o valor
                                            investido até o momento e prazo restante.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-center items-center w-full'>
                            <a href="#home" className='w-1/3'>
                                <button className='mt-4 h-10 w-full rounded-lg text-sm border-none text-white cursor-pointer' style={{ backgroundColor: '#c76f36' }}>Vender meu consórcio</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* Componente responsivo para celular */}
            <div className='w-full flex mx-auto my-auto h-sm:flex hidden px-4' style={{ height: '900px', backgroundColor: '#fff7ee' }}>
                <div className='flex space-x-3 mx-auto my-auto w-full'>
                    <div className='w-full h-full'>
                        <div className='w-full'>
                            <div className='mt-8'>
                                <span style={{ color: '#f5be88' }}>Nossos compromissos</span>
                            </div>
                            <div className='mt-4'>
                                <span className='text-2xl font-extrabold font-raleway'>Garantia de <br />Transparência e Segurança</span>
                            </div>
                            <div className='mt-4'>
                                <span className='text-xl font-bold font-raleway'>
                                    Vender seu consórcio pode gerar <br /> algumas preocupações e dúvidas.
                                </span>
                            </div>
                            <div className='mt-4'>
                                <span className='text-base'>
                                    Na Objetiva Consórcio, valorizamos a transparência e a <br />
                                    segurança em todas as nossas transações. Queremos <br />
                                    que você se sinta confiante em tomar essa decisão.
                                </span>
                            </div>
                        </div>
                        <div className='h-96 w-full mt-5 flex items-center space-x-2'>
                            <div onClick={handlePrev} style={{ cursor: 'pointer' }}>
                                <img src="left.png" alt="logo" />
                            </div>
                            <div className='bg-white h-full w-full rounded-md drop-shadow-sm'>
                                <div className='relative'>
                                    <img src={cards[currentCard].image} alt="logo" className='w-full h-36' />
                                    <div className='absolute top-24 left-0 w-full flex justify-center' style={{ zIndex: 1 }}>
                                        <div className='w-full flex justify-center'>
                                            <div className='w-1/2'></div>
                                            <div className='w-1/2 flex justify-center items-center'>
                                                <img src={cards[currentCard].icon} alt="logo" className='w-20 h-20' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='p-4 mt-5'>
                                    <div>
                                        <span className='text-base font-semibold'>{cards[currentCard].title}</span>
                                    </div>
                                    <div className='mt-3'>
                                        <span className='text-base'>
                                            {cards[currentCard].description}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div onClick={handleNext} style={{ cursor: 'pointer' }}>
                                <img src="right.png" alt="logo" />
                            </div>
                        </div>
                        <div className='w-full h-20 flex justify-center items-center'>
                            <a href="#home" className='w-full'>
                                <button className='mt-4 h-10 w-full rounded-lg text-sm border-none text-white cursor-pointer' style={{ backgroundColor: '#c76f36' }}>Vender meu consórcio</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HomeCommitment;