import React, { useEffect } from 'react';
import './Footer.css';
import { AiFillPhone } from 'react-icons/ai';
import { BsFacebook, BsInstagram, BsLinkedin, BsWhatsapp } from 'react-icons/bs';

function Footer() {
  useEffect(() => {
    if (!document.getElementById('ra-embed-verified-seal')) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'ra-embed-verified-seal';
      script.src = 'https://s3.amazonaws.com/raichu-beta/ra-verified/bundle.js';
      script.setAttribute('data-id', 'ZVJSbWkycFNpR05HcF9HaTpvYmpldGl2YS1zb2x1Y29lcy1lbS1jb25zb3JjaW8=');
      script.setAttribute('data-target', 'ra-verified-seal');
      script.setAttribute('data-model', '2');
      document.getElementById('ra-verified-seal').appendChild(script);


      const script2 = document.createElement('script');
      script2.type = 'text/javascript';
      script2.id = 'ra-embed-verified-seal';
      script2.src = 'https://s3.amazonaws.com/raichu-beta/ra-verified/bundle.js';
      script2.setAttribute('data-id', 'ZVJSbWkycFNpR05HcF9HaTpvYmpldGl2YS1zb2x1Y29lcy1lbS1jb25zb3JjaW8=');
      script2.setAttribute('data-target', 'ra-verified-seal-2');
      script2.setAttribute('data-model', '1');
      document.getElementById('ra-verified-seal-2').appendChild(script2);
    }
  }, []);

  return (
    <>
      <div className='w-full flex space-x-6 mx-auto my-auto h-96 bg-red-500 h-sm:hidden' style={{ height: '410px', backgroundColor: '#004e80' }}>
        <div className='flex space-x-6 mx-auto my-auto'>
          <div className='h-96 flex' style={{ width: '900px', height: '380px' }}>
            <div className="w-1/2">
              <div className="w-full h-1/3 flex justify-center items-end">
                <img className="h-16" src="objetiva-footer.png" alt='logo' />
              </div>
              <div className="w-full h-1/3 flex justify-center items-center">
                <div className="w-full flex items-center justify-center mt-6">
                  <div id="ra-verified-seal-2" />
                </div>
              </div>
              <div className="w-full h-1/3 flex justify-center items-center">
                <div className="flex">
                  <a href="https://www.facebook.com/objetivaconsorcio" target="_blank" rel="noreferrer">
                    <BsFacebook className="text-white text-lg mt-2 ml-3 mr-3" />
                  </a>
                  <a href="https://www.instagram.com/objetivaconsorcio" target="_blank" rel="noreferrer">
                    <BsInstagram className="text-white text-lg mt-2 ml-3 mr-3" />
                  </a>
                  <a href="https://www.linkedin.com/company/objetivaconsorcio" target="_blank" rel="noreferrer">
                    <BsLinkedin className="text-white text-lg mt-2 ml-3 mr-3" />
                  </a>
                  <a href="https://wa.me/551132077230" target="_blank" rel="noreferrer">
                    <BsWhatsapp className="text-white text-lg mt-2 ml-3 mr-3" />
                  </a>
                </div>
              </div>
            </div>
            <div className="w-1/2 space-y-1">
              <div className="w-full h-1/3 flex items-end">
                <div className="w-1/2">
                  <span className="text-white">OBJETIVA - SOLUÇÕES EM CONSÓRCIO S/S LTDA</span>
                  <br />
                  <span className="text-white">CNPJ: 13.282.501/0001-50</span>
                </div>
              </div>
              <div className='"w-full h-1/3'>
                <div className="w-full h-1/3 flex items-center">
                  <div className="w-1/2 flex">
                    <div className="w-1/6">
                      <img className="h-6" src="letter.png" alt='logo' />
                    </div>
                    <div className="w-5/6">
                      <span className="text-white">comercial@objetiva.com.br</span>
                    </div>
                  </div>
                </div>
                <div className="w-full h-1/3 flex items-center">
                  <div className="w-1/2 flex">
                    <div className="w-1/6">
                      <img className="h-6" src="phone.png" alt='logo' />
                    </div>
                    <div className="w-5/6">
                      <span className="text-white">(11) 3207-6869</span>
                    </div>
                  </div>
                </div>
                <div className="w-full h-1/3 flex items-center">
                  <div className="w-1/2 flex">
                    <div className="w-1/6">
                      <img className="h-6" src="whatsapp.png" alt='logo' />
                    </div>
                    <div className="w-5/6">
                      <span className="text-white">(11) 3207-7230</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full h-1/3">
                <div className="w-1/2 flex">
                  <div className="w-1/6">
                    <img className="h-6" src="map.png" alt='logo' />
                  </div>
                  <div className="w-5/6" >
                    <span className="text-white text-sm">Rua Espírito Santo, 273 - Aclimação - São Paulo - SP</span>
                    <br />
                    <span className="text-white">CEP: 01526-020</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Componente responsivo para celular */}
      <div className='w-full flex space-x-6 mx-auto my-auto bg-red-500 h-sm:block hidden' style={{ backgroundColor: '#004e80' }}>
        <div className='flex space-x-6 mx-auto my-auto'>
          <div className='w-full'>
            <div className="w-full flex mt-5">
              <div className="w-full h-1/3 flex justify-center items-end">
                <img className="h-16" src="objetiva-footer.png" alt='logo' />
              </div>
              <div className="w-full flex justify-center items-center content-center">
                <div className="flex space-x-6">
                  <a href="https://www.facebook.com/objetivaconsorcio" target="_blank" rel="noreferrer">
                    <BsFacebook className="text-white text-lg" />
                  </a>
                  <a href="https://www.instagram.com/objetivaconsorcio" target="_blank" rel="noreferrer">
                    <BsInstagram className="text-white text-lg" />
                  </a>
                  <a href="https://www.linkedin.com/company/objetivaconsorcio" target="_blank" rel="noreferrer">
                    <BsLinkedin className="text-white text-lg" />
                  </a>
                  <a href="https://wa.me/551132077230" target="_blank" rel="noreferrer">
                    <BsWhatsapp className="text-white text-lg" />
                  </a>
                </div>
              </div>
            </div>
            <div className="w-full space-y-6 mt-6">
              <div className="w-full h-1/3 flex items-center justify-center">
                <div className="w-1/2 text-center">
                  <span className="text-white">OBJETIVA - SOLUÇÕES EM CONSÓRCIO S/S LTDA</span>
                  <br />
                  <span className="text-white">CNPJ: 13.282.501/0001-50</span>
                </div>
              </div>
              <div className="w-full h-1/3 flex items-center justify-center">
                <div className="flex">
                  <div className="w-1/6">
                    <img className="h-6" src="letter.png" alt='logo' />
                  </div>
                  <div className="w-5/6">
                    <span className="text-white">comercial@objetiva.com.br</span>
                  </div>
                </div>
              </div>
              <div className="w-full h-1/3 flex items-center justify-center">
                <div className="flex space-x-2">
                  <div className="w-1/6">
                    <img className="h-6" src="phone.png" alt='logo' />
                  </div>
                  <div className="w-5/6">
                    <span className="text-white">(11) 3207-6869</span>
                  </div>
                </div>
              </div>
              <div className="w-full h-1/3 flex items-center justify-center">
                <div className="flex space-x-2">
                  <div className="w-1/6">
                    <img className="h-6" src="whatsapp.png" alt='logo' />
                  </div>
                  <div className="w-5/6">
                    <span className="text-white">(11) 3207-7230</span>
                  </div>
                </div>
              </div>
              <div className="w-full h-1/3 flex items-center justify-center">
                <div className="w-2/3 flex">
                  <div className="w-1/6">
                    <img className="h-6" src="map.png" alt='logo' />
                  </div>
                  <div className="w-5/6" >
                    <span className="text-white text-sm">Rua Espírito Santo, 273 - Aclimação - São Paulo - SP - CEP: 01526-020</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full flex items-center justify-center mt-6 mb-6">
              <div id="ra-verified-seal" />
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
