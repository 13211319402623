import { differenceInMonths, parseISO, startOfMonth } from 'date-fns';

const calcularMesesDiferenca = (dataEspecifica) => {
  const dataEspecificaDate = parseISO(dataEspecifica);
  const hoje = new Date();
  const formatado = `${hoje.getFullYear()}-${String(hoje.getMonth() + 1).padStart(2, '0')}-${String(hoje.getDate()).padStart(2, '0')}`;
  const mesesDiferencaNew = differenceInMonths(dataEspecificaDate, parseISO(formatado));
  
  return mesesDiferencaNew;
};

const calcularMesesDiferencaNew = (dataEspecifica) => {
  const dataEspecificaDate = parseISO(dataEspecifica);
  const hoje = new Date();
  const formatado = `${hoje.getFullYear()}-${String(hoje.getMonth() + 1).padStart(2, '0')}-${String(hoje.getDate()).padStart(2, '0')}`;

  const dataInicio = startOfMonth(dataEspecificaDate);
  const dataFim = startOfMonth(parseISO(formatado));

  const mesesDiferencaNew = differenceInMonths(dataInicio, dataFim);
  
  return mesesDiferencaNew;
};

function customRound(value) {
  return Math.round(value / 1000) * 1000; 
}

const calcularResultadoV2 = (credito, percentual, dataencerramento, debugMode) => {
  let valorDoBem = parseFloat(credito.replace('.', '').replace(',', '.'));

  const valorPago = parseFloat(percentual.replace(',', '.'));
  const mesesDiferenca = calcularMesesDiferencaNew(dataencerramento);
  const valorRecebivel = valorDoBem * (valorPago / 100);
  
  let resultadoCalculo = 0;
  
  //console.log('--- NOVO CÁLCULO ---');
  //console.log('Debug Mode: ', debugMode);
  //console.log('Valor do bem: ', valorDoBem);
  //console.log('Valor do recebível: ', valorRecebivel);  
  //console.log('Valor pago (porcentagem):', valorPago);
  //console.log('Meses de diferença: ', mesesDiferenca);
  
  let percentualProposta = 0;
  
  if(mesesDiferenca <= 71){
    percentualProposta = 0.505 - (mesesDiferenca * 0.005);  
    
  }else{
    percentualProposta = 0.15;
  }  

  //console.log('Percentual da Proposta :', percentualProposta);

  resultadoCalculo = valorDoBem * (valorPago * percentualProposta / 100);

  //console.log('Resultado do cálculo (SEM ARREDONDAMENTO): ', resultadoCalculo);

  let lucro = 0;
  
  if (valorRecebivel <= 9999) {
    lucro = 0;    
    //console.log('RECUSADO: Valor do recebível menor que 9999');
  }else{
    lucro = valorRecebivel - resultadoCalculo;
  }

  if(lucro < 5000){
    resultadoCalculo = 0;
    //console.log('RECUSADO: Lucro menor que 5000; VALOR='+lucro);
  }else{
    resultadoCalculo = customRound(resultadoCalculo);
  }

  if(mesesDiferenca <= 3 || mesesDiferenca > 120){
    resultadoCalculo = 0;
    //console.log('RECUSADO: Meses de diferença menor que 3 ou maior que 120');
  }
  
  //console.log('Lucro bruto: ', lucro);
  //console.log('Resultado do cálculo (AREDONDADO): ', resultadoCalculo);

  return resultadoCalculo.toFixed(2);
};

export default calcularResultadoV2;
