import React from 'react';
import './HomeSolution.css';
import 'react-toastify/dist/ReactToastify.css'

function HomeSolution(){
    return(
        <>
        <div className='flex space-x-6 mx-auto my-auto h-sm:hidden'>
            <div className='flex h-80 ' style={{ width: '800px' }}>
                <div style={{ width: '340px' }}>
                    <img src="moneySolution.png" alt="logo" />
                </div>
                <div style={{ width: '460px' }}>
                    <div>
                        <span style={{ color: '#f5be88' }}>Para você</span>
                    </div>
                    <div className='mt-3'>
                        <span className='text-3xl font-extrabold font-raleway'>Precisando <br /> de dinheiro rápido?</span>
                    </div>
                    <div className='mt-3'>
                        <span className='text-base font-bold font-raleway'>Temos uma solução para você!</span>
                    </div>
                    <div className='mt-3'>
                        <span className='text-sm'>Aqui na Objetiva Consórcio, sua cota cancelada vale dinheiro. <br /> Nós compramos sua cota cancelada e pagamos a vista de forma <br /> rápida e sem burocracia.</span>
                    </div>
                    <div className='mt-3'>
                        <span className='text-xs font-raleway'>Consulte nossas regras e parâmetros</span>
                    </div>
                    <a href="#home"><button className='mt-4 h-10 w-2/3 rounded-lg text-sm border-none text-white cursor-pointer' style={{ backgroundColor: '#c76f36' }}>Vender meu consórcio</button></a>
                </div>
            </div>
        </div>
        {/* Componente responsivo para celular */}
        <div className='flex mx-auto my-auto h-sm:flex hidden px-4'>
            <div className='h-full w-full'>
                <div className='w-full h-80'>
                    <img src="moneySolution.png" alt="logo" className='w-full h-full' />
                </div>
                <div className='w-full mt-4'>
                    <div>
                        <span style={{ color: '#f5be88' }}>Para você</span>
                    </div>
                    <div className='mt-3'>
                        <span className='text-2xl font-extrabold font-raleway'>Precisando de dinheiro rápido?</span>
                    </div>
                    <div className='mt-3'>
                        <span className='text-base font-bold font-raleway'>Temos uma solução para você!</span>
                    </div>
                    <div className='mt-3'>
                        <span className='text-base'>Aqui na Objetiva Consórcio, sua cota cancelada vale dinheiro. Nós compramos sua cota cancelada e pagamos a vista de forma rápida e sem burocracia.</span>
                    </div>
                    <div className='mt-3'>
                        <span className='text-xs font-raleway'>Consulte nossas regras e parâmetros</span>
                    </div>
                    <a href="#home"><button className='mt-4 h-10 w-full rounded-lg text-sm border-none text-white cursor-pointer' style={{ backgroundColor: '#c76f36' }}>Vender meu consórcio</button></a>
                </div>
            </div>
        </div>
        </>
    );
}

export default HomeSolution;