import React from 'react';
import './HomeAdvantage.css';
import 'react-toastify/dist/ReactToastify.css'

function HomeAdvantage() {
    return (
        <>
            <div className='relative flex justify-center items-center mx-auto my-auto h-80 h-sm:hidden'>
                <div className='flex h-44 rounded-xl' style={{ width: '900px', backgroundColor: '#d9cfff' }}>
                    <div style={{ width: '300px' }}>
                        <img src="agreement.png" alt="logo" className="absolute top-0 h-80" />
                    </div>
                    <div style={{ width: '460px' }} className="flex flex-col justify-center">
                        <div>
                            <span className='font-semibold' style={{ color: '#c76f36' }}>Vantagem</span>
                        </div>
                        <div className='mt-3'>
                            <span className='text-3xl font-extrabold font-raleway'>Receba seu dinheiro à vista</span>
                        </div>
                        <div className='flex space-x-5'>
                            <a href="#home" className='w-2/3'>
                                <button className='mt-4 h-10 w-full rounded-lg text-sm border-none text-white cursor-pointer' style={{ backgroundColor: '#c76f36' }}>Vender meu consórcio</button>
                            </a>
                            <a href="#footer" className='w-2/3'>
                                <button className='mt-4 h-10 w-full rounded-lg text-sm cursor-pointer border bg-white' style={{ color: '#c76f36', borderColor: '#c76f36' }}>Falar com especialista</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* Componente responsivo para celular */}
            <div className='relative flex justify-center items-center mx-auto my-auto h-96 h-sm:flex hidden mt-10' style={{ height: '470px' }}>
                <div className='rounded-xl w-full p-4 h-full' style={{ backgroundColor: '#d9cfff' }}>
                    <div className='w-full h-1/2 flex justify-center items-center content-center'>
                        <img src="agreement.png" alt="logo" className="absolute -top-20 h-80" />
                    </div>
                    <div className="flex flex-col justify-center w-full h-1/2">
                        <div>
                            <span className='font-semibold' style={{ color: '#c76f36' }}>Vantagem</span>
                        </div>
                        <div className='mt-3'>
                            <span className='text-2xl font-extrabold font-raleway'>Receba seu dinheiro à vista</span>
                        </div>
                        <div className='space-y-5'>
                            <a href="#home" className='w-full'>
                                <button className='mt-4 h-12 w-full rounded-lg text-sm border-none text-white cursor-pointer' style={{ backgroundColor: '#c76f36' }}>Vender meu consórcio</button>
                            </a>
                            <a href="#footer" className='w-full'>
                                <button className='mt-4 h-12 w-full rounded-lg text-sm cursor-pointer border bg-white' style={{ color: '#c76f36', borderColor: '#c76f36' }}>Falar com especialista</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default HomeAdvantage;