import { toast } from 'react-toastify';

export const notify = (input) => {
    toast.error('O campo ' + input +' não pode ficar em branco');    
};

export const notifyPassword = (input) => {
    toast.error(input);    
};

export const notifySuccess = () => {
    toast.success('Cadastro realizado com sucesso!');    
};

export const notifySuccessEdit = () => {
    toast.success('Alteração realizada com sucesso!');    
};

export const notifyInfo = () => {
    toast.info('Email ja está cadastrado!');    
};

export const notifyError = (input) => {
    toast.error(input);    
};

export const notifyInfoMail = () => {
    toast.info('Foi enviado um email com o passo a passo!');    
};