import React from "react";
import Calculadora from "../../components/Calculadora/Calculadora";
import HomeDescription from "../../components/HomeDescription/HomeDescription";
import VideoBackground from "../../components/VideoBackground/VideoBackground";
import HomeAboutUS from "../../components/HomeAboutUs/HomeAboutUs";
import HomeSolution from "../../components/HomeSolution/HomeSolution";
import HomeSteps from "../../components/HomeSteps/HomeSteps";
import HomeCommitment from "../../components/HomeCommitment/HomeCommitment";
import HomeDepositions from "../../components/HomeDepositions/HomeDepositions";
import HomeAdvantage from "../../components/HomeAdvantage/HomeAdvantage";
import HomeQuerys from "../../components/HomeQuerys/HomeQuerys";
import Footer from "../../components/Footer/Footer";
import video from "../../assets/calculadora.mp4"; 

import "./Home.css";

function Home() {
  return (
    <>
      <section id="home" className="flex flex-col absolute h-screen min-h-full w-full">
        <div className="h-sm:hidden">
          <VideoBackground />
        </div>
        <div className="flex w-full z-0 pt-8 pb-5 h-md:pt-10 h-md:pb-10 h-lg:pb-40 h-lg:pt-40 h-sm:block">
          <div className="w-1/2 h-sm:hidden">
          </div>
          <div className="w-full hidden h-sm:block">
            <video width="100%" height="100%" controls autoplay="autoplay" muted loop className="shadow-3xl mt-6 h-sm:p-4 h-sm:pt-0">
              <source src={video} type="video/mp4" />
            </video>
          </div>
          <div className="mx-auto my-auto h-sm:flex h-sm:justify-center h-sm:items-center">
            <Calculadora />
          </div>
        </div>
        <div className="flex w-full z-0 pt-9 pb-9">
          <HomeDescription />
        </div>
        <div className="flex w-full z-0 pt-9 pb-9">
          <HomeAboutUS />
        </div>
        <div className="flex w-full z-0 pt-9 pb-9">
          <HomeSolution />
        </div>
        <div className="flex w-full z-0 pt-9 pb-9">
          <HomeSteps />
        </div>
        <div className="flex w-full z-0 pt-9 pb-9">
          <HomeCommitment />
        </div>
        <div className="flex w-full z-0 pt-9 pb-9">
          <HomeDepositions />
        </div>
        <div className="flex w-full z-0 pt-9 pb-9">
          <HomeAdvantage />
        </div>
        <div className="flex w-full z-0 pt-9 pb-9">
          <HomeQuerys />
        </div>
        <div className="flex w-full z-0 pt-9" id="footer">
          <Footer/> 
        </div>
      </section>
    </>
  );
}

export default Home;
