import React, { useState } from 'react';
import './LeadContactForm.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

function LeadContactForm() {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [celular, setCelular] = useState('');
  
  const notify = () => {
    toast.success('🚀 Cadastrado com sucesso!');    
  };

  const handleSubmit = (event) => {
    event.preventDefault();    
    notify();
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col  rounded-[30px] px-5">
      <ToastContainer />
      <div className=''>  
        <label htmlFor="nome" className='text-white normal-case pl-0 pb-0 block font-normal font-raleway'>Nome</label>
        <input 
          type="text" 
          id="nome"
          value={nome} 
          onChange={(event) => setNome(event.target.value)}
          className='w-full p-1 bg-white rounded-lg h-8'
          required
          />
      </div>

      <div className=''>  
        <label htmlFor="nome" className='text-white normal-case pl-0 pb-0 block font-normal font-raleway'>Celular</label>
        <input 
          type="text" 
          id="celular"
          value={celular} 
          onChange={(event) => setCelular(event.target.value)}
          className='w-full p-1 bg-white rounded-lg h-8'
          required
          placeholder='(99) 99999-9999'
          mask="(99) 99999-9999"
          />
      </div>
      <button 
        className='mt-3 w-full rounded-lg text-sm border-none text-white cursor-pointer py-2'
        style={{ backgroundColor: '#004e80' }} 
        type="submit">
        Quero vender meu <br />
        consórcio cancelado
      </button>
    </form>
  );
}

export default LeadContactForm;
