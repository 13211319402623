import React, { useState, useEffect } from 'react';
import './LoginPPR.css';
import { setCookie, getCookie } from 'cookies-next';
import { showRegister, showMainPpr, forgotPasswordScreen } from '../PPR/utilsPPR';
import { loginppr } from '../../utils/api';
import { notify, notifyError } from '../../utils/notify';
import RegisterPPR from '../RegisterPPR/RegisterPPR';
import PasswordPPR from '../PasswordPPR/PasswordPPR'

export default function LoginPPR() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenPassword, setModalOpenPassword] = useState(false);

  const openModalPassword = () => {
    setModalOpenPassword(true);
  };

  const closeModalPassword = () => {
    setModalOpenPassword(false);
  };

  const openModalRegister = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const token = getCookie('token');
    if (token) {
      showMainPpr();
    }
  }, []);

  const checksInput = () => {
    var input = "";
    if (email === '') {
      input = "email";
      notify(input);
      return false;
    } else if (password === '') {
      input = "senha";
      notify(input);
      return false;
    }
    return true;
  }

  const doLogin = async (event) => {
    event.preventDefault();

    if (!checksInput()) {
      return false;
    }

    const data = {
      email,
      password
    }

    try {
      const response = await loginppr(data);

      if (response.status === 401) {
        var input = "Usuário ou senha incorretos";
        notifyError(input);
      }
      const jsonToken = await response.json();

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      if (jsonToken) {
        setCookie('token', jsonToken);

        showMainPpr();
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <form className='p-8 pt-5 rounded-lg font-sans shadow-2xl shadow-blue-500/50' style={{ width: '400px', backgroundColor: '#004e80' }}>
        <h3 className='text-center normal-case text-white font-sans my-6 text-xl font-raleway font-medium'>Bem-vindo ao programa</h3>
        <div className='mb-4'>
          <input type="text" className='w-full bg-white rounded-lg h-10 p-3' placeholder='Login' value={email} onChange={(event) => setEmail(event.target.value)} />
        </div>
        <div className='mb-4'>
          <input type='password' className='w-full bg-white rounded-lg h-10 p-3' placeholder='Senha' value={password} onChange={(event) => setPassword(event.target.value)} />
        </div>
        <button type="submit" className='w-full text-white border-none rounded-lg py-2 px-4 cursor-pointer h-10 mb-4' onClick={doLogin} style={{ backgroundColor: '#c76f36' }}>Entrar</button>
        <div className='flex '>
          <div className='w-1/2'>
            <button type="button" className='w-full text-white border rounded-lg py-2 px-4 cursor-pointer h-10 ' onClick={openModalRegister} style={{ backgroundColor: '#004e80', border: '1px solid white' }}>Cadastre-se</button>
          </div>
          <div className='w-1/2'>
            <button type="button" className='w-full text-white border-none rounded-lg py-2 px-4 cursor-pointer h-10 ' onClick={openModalPassword} style={{ backgroundColor: '#004e80' }}>Esqueceu a senha?</button>
          </div>
        </div>
      </form>
      <RegisterPPR isOpen={modalOpen} closeModal={closeModal} />
      <PasswordPPR isOpen={modalOpenPassword} closeModal={closeModalPassword} />
    </>
  );
}
