import './Services.css';
import FormIntermediation from '../../components/FormIntermediation/FormIntermediation';
import Footer from '../../components/Footer/Footer';
import AboutIntermediation from '../../components/AboutIntermediation/AboutIntermediation';

function Servicos() {
  return (
    <section className="flex flex-col absolute h-screen min-h-full w-full">
      <div className="relative h-sm:hidden">
        <div className="flex w-full z-10 pt-9 pb-9 absolute">
          <div className="mx-auto">
            <div className='mt-4'>
              <span className='text-3xl font-extrabold font-raleway text-white'>Você que atua no mercado <br /> de consórcio pode se tornar <br /> um de nossos intermediários </span>
            </div>
            <div className='mt-4'>
              <span className='text-base text-white font-bold'>Conheça nossos modelos<br /> de intermediação</span>
            </div>
          </div>
          <div className="mx-auto my-auto">
            <FormIntermediation />
          </div>
        </div>
        <img src="services.png" alt="logo" className='z-0 top-0 left-0 h-80 w-full' />
      </div>
      <div className="relative h-sm:flex hidden">
        <div className="w-full z-10 pt-9 pb-9 absolute">
          <div className="mx-auto px-4">
            <div className='mt-4'>
              <span className='text-3xl font-extrabold font-raleway text-white'>Você que atua no mercado de <br /> consórcio pode se <br /> tornar um <br /> de nossos intermediários </span>
            </div>
            <div className='mt-4'>
              <span className='text-base text-white font-bold'>Conheça nossos modelos<br /> de intermediação</span>
            </div>
          </div>
          <div className="mx-auto my-auto">
            <FormIntermediation />
          </div>
        </div>
        <img src="services-mobile.png" alt="logo" className='z-0 top-0 left-0 h-full w-full h-sm:flex hidden' />
      </div>
      <div className="flex w-full z-0 mt-20">
        <AboutIntermediation />
      </div>
      <div className="flex w-full z-0">
        <Footer />
      </div>
    </section>
  );
}

export default Servicos;