import React, { useState } from 'react';
import './HomeQuerys.css';
import 'react-toastify/dist/ReactToastify.css';

function HomeQuerys() {
    const [question1, setQuestion1] = useState(true);
    const [question2, setQuestion2] = useState(true);
    const [question3, setQuestion3] = useState(true);
    const [question4, setQuestion4] = useState(true);
    const [question5, setQuestion5] = useState(true);
    const [question6, setQuestion6] = useState(true);

    const toggleCollapse1 = () => {
        setQuestion1(!question1);
    };
    
    const toggleCollapse2 = () => {
        setQuestion2(!question2);
    };
    
    const toggleCollapse3 = () => {
        setQuestion3(!question3);
    };

    const toggleCollapse4 = () => {
        setQuestion4(!question4);
    };

    const toggleCollapse5 = () => {
        setQuestion5(!question5);
    };
    
    const toggleCollapse6 = () => {
        setQuestion6(!question6);
    };

    return (
        <div className='justify-center mx-auto my-auto h-sm:p-4' style={{ width: '800px' }}>
            <div className='text-center'>
                <span style={{ color: '#f5be88' }}>Dúvidas</span>
            </div>
            <div className='text-center'>
                <span className='text-3xl font-extrabold font-raleway'>Perguntas frequentes</span>
            </div>
            <div className='mt-4'>
                <div className="rounded-md overflow-hidden mb-4">
                    <button className="flex justify-between items-center px-4 py-2 bg-gray-100 w-full text-left focus:outline-none" onClick={toggleCollapse1}>
                        <span className="font-bold text-xl">Por que vender minha cota de consórcio cancelada?</span>
                        <img src={question1 ? "chevron_down.png" : "chevron_down.png"} alt="" />
                    </button>
                    {question1 && (
                        <div className="px-4 py-2 pt-1 bg-gray-100">
                            <p className="text-base">
                                Vender sua cota de consórcio cancelada pode ser uma ótima opção para recuperar parte do investimento 
                                feito sem ter que esperar até o final do grupo. Isso é especialmente útil se você precisa de dinheiro 
                                imediato, além de obter um retorno financeiro mais rápido e evitar a desvalorização do valor investido 
                                no pagamento das parcelas mensais.
                            </p>
                        </div>
                    )}
                </div>
                <div className="rounded-md overflow-hidden mb-4">
                    <button className="flex justify-between items-center px-4 py-2 bg-gray-100 w-full text-left focus:outline-none" onClick={toggleCollapse2}>
                        <span className="font-bold text-xl">Como funciona o processo de venda de cota cancelada?</span>
                        <img src={question2 ? "chevron_down.png" : "chevron_down.png"} alt="" />
                    </button>
                    {question2 && (
                        <div className="px-4 py-2 pt-1 bg-gray-100">
                            <p className="text-base">
                                De forma simples e transparente avaliamos a sua cota cancelada pelo melhor valor de mercado, sendo que 
                                essa avaliação (proposta) pode ser alcançada também pelo simples uso da calculadora disponível no nosso site. 
                                Em seguida, com a aceitação da proposta pelo consorciado, a negociação é celebrada mediante instrumento de cessão 
                                de crédito (parcelas pagas) e PAGAMENTO IMEDIATO do valor da proposta.
                            </p>
                        </div>
                    )}
                </div>
                <div className="rounded-md overflow-hidden mb-4">
                    <button className="flex justify-between items-center px-4 py-2 bg-gray-100 w-full text-left focus:outline-none" onClick={toggleCollapse3}>
                        <span className="font-bold text-xl">Quais as outras vantagens de vender meu consórcio cancelado?</span>
                        <img src={question3 ? "chevron_down.png" : "chevron_down.png"} alt="" />
                    </button>
                    {question3 && (
                        <div className="px-4 py-2 pt-1 bg-gray-100">
                            <p className="text-base">
                                Além de antecipar os valores pagos ao fundo comum da sua cota de consórcio cancelada, a forma de cálculo 
                                que utilizamos não leva em conta a multa cobrada pelas administradoras por supostos prejuízos causados 
                                pelo cancelamento da cota, e evita a cobrança de taxa de permanência aplicada para o caso de demora no 
                                recebimento após o encerramento do grupo.
                            </p>
                        </div>
                    )}
                </div>
                <div className="rounded-md overflow-hidden mb-4">
                    <button className="flex justify-between items-center px-4 py-2 bg-gray-100 w-full text-left focus:outline-none" onClick={toggleCollapse4}>
                        <span className="font-bold text-xl">Qualquer cota de consórcio cancelada pode ser vendida?</span>
                        <img src={question4 ? "chevron_down.png" : "chevron_down.png"} alt="" />
                    </button>
                    {question4 && (
                        <div className="px-4 py-2 pt-1 bg-gray-100">
                            <p className="text-base">
                                Adquirimos cotas canceladas cujo valor recebível seja igual ou superior a R$ 5.000,00 (cinco mil reais), 
                                ainda que a proposta não seja emitida pela calculadora disponível no nosso site. O valor recebível perante 
                                a administradora, no encerramento do grupo, pode ser apurado pela simples multiplicação do percentual amortizado 
                                (pago) ao fundo comum da cota pelo valor do bem atual e considerado a data de encerramento do grupo.
                            </p>
                        </div>
                    )}
                </div>
                <div className="rounded-md overflow-hidden mb-4">
                    <button className="flex justify-between items-center px-4 py-2 bg-gray-100 w-full text-left focus:outline-none" onClick={toggleCollapse5}>
                        <span className="font-bold text-xl">A venda de cota cancelada precisa de autorização da administradora do grupo de consórcio?</span>
                        <img src={question5 ? "chevron_down.png" : "chevron_down.png"} alt="" />
                    </button>
                    {question5 && (
                        <div className="px-4 py-2 pt-1 bg-gray-100">
                            <p className="text-base">
                                Por tratar-se de cota cancelada, portanto, de consorciado excluído do grupo de consórcio, a venda da cota 
                                (cessão de crédito) não depende da anuência da administradora, pois, nesse caso nenhuma obrigação é transferida, 
                                mas apenas o direito de recebimento do crédito em relação ao fundo comum das parcelas pagas, no momento oportuno.
                            </p>
                        </div>
                    )}
                </div>
                <div className="rounded-md overflow-hidden mb-4">
                    <button className="flex justify-between items-center px-4 py-2 bg-gray-100 w-full text-left focus:outline-none" onClick={toggleCollapse6}>
                        <span className="font-bold text-xl">O consorciado excluído do grupo precisa comunicar a administradora sobre a venda da cota cancelada?</span>
                        <img src={question6 ? "chevron_down.png" : "chevron_down.png"} alt="" />
                    </button>
                    {question6 && (
                        <div className="px-4 py-2 pt-1 bg-gray-100">
                            <p className="text-base">
                                Feita a venda da cota de consórcio cancelada, nos cabe notificar a administradora para dar ciência da cessão de 
                                crédito – negociação realizada entre as partes.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default HomeQuerys;
