import React from 'react';
import './HomeDescription.css';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import 'react-toastify/dist/ReactToastify.css'

function HomeDescription() {
  return (
    <>
      <div className='flex space-x-6 mx-auto my-auto h-sm:hidden'>
        <div className="h-48 w-64 bg-white rounded-xl text-center flex flex-col justify-center items-center p-7 drop-shadow-sm">
          <img className="h-16" src="wallet.png" alt="logo" />
          <span className="font-raleway">Pagamento imediato</span>
        </div>
        <div className="h-48 w-64 bg-white rounded-xl text-center flex flex-col justify-center items-center p-7 drop-shadow-sm">
          <img className="h-16" src="like.png" alt="logo" />
          <span className="font-raleway">Negociação transparente, segura e sem burocracia;</span>
        </div>
        <div className="h-48 w-64 bg-white rounded-xl text-center flex flex-col justify-center items-center p-7 drop-shadow-sm">
          <img className="h-16" src="workbank.png" alt="logo" />
          <span className="font-raleway">Procedimento 100% online;</span>
        </div>
        <div className="h-48 w-64 bg-white rounded-xl text-center flex flex-col justify-center items-center p-7 drop-shadow-sm">
          <img className="h-16" src="proft.png" alt="logo" />
          <span className="font-raleway">Cota de consórcio em ativo de alta liquidez.</span>
        </div>
      </div>
      {/* Componente resposivo para celular */}
      <div className='w-full px-6 space-y-4 h-sm:block hidden'>
        <div className='flex space-x-4 mx-auto my-auto w-full'>
          <div className="h-44 w-1/2 bg-white rounded-xl text-center flex flex-col justify-center items-center p-2 drop-shadow-sm">
            <img className="h-16" src="wallet.png" alt="logo" />
            <span className="font-raleway">Pagamento imediato</span>
          </div>
          <div className="h-44 w-1/2 bg-white rounded-xl text-center flex flex-col justify-center items-center p-2 drop-shadow-sm">
            <img className="h-16" src="like.png" alt="logo" />
            <span className="font-raleway">Negociação transparente, segura e sem burocracia;</span>
          </div>
        </div>
        <div className='flex space-x-4 mx-auto my-auto'>
          <div className="h-44 w-1/2 bg-white rounded-xl text-center flex flex-col justify-center items-center p-2 drop-shadow-sm">
            <img className="h-16" src="workbank.png" alt="logo" />
            <span className="font-raleway">Procedimento 100% online;</span>
          </div>
          <div className="h-44 w-1/2 bg-white rounded-xl text-center flex flex-col justify-center items-center p-2 drop-shadow-sm">
            <img className="h-16" src="proft.png" alt="logo" />
            <span className="font-raleway">Cota de consórcio em ativo de alta liquidez.</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeDescription;