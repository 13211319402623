import React from 'react';
import './UsDifferential.css';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import 'react-toastify/dist/ReactToastify.css'

function UsDifferential() {
    return (
        <>
            <div className='flex space-x-6 mx-auto my-auto h-sm:hidden'>
                <div className='flex h-full' style={{ width: '900px' }}>
                    <div style={{ width: '510px' }} className='p-5'>
                        <div>
                            <span style={{ color: '#f5be88' }}>Para você</span>
                        </div>
                        <div className='mt-2'>
                            <span className='text-3xl font-extrabold font-raleway'>Nossa Missão</span>
                        </div>
                        <div className='mt-2'>
                            <span className='text-sm'>Queremos informar os consorciados desistentes ou excluídos sobre a possibilidade de vender seus direitos sobre as parcelas pagas na cota cancelada pelo melhor preço de mercado. Para isso, oferecemos uma calculadora em nosso site que, ao fornecer dados como o valor atual do bem, percentual pago e data de encerramento do grupo, informa imediatamente o valor que oferecemos pela cota cancelada.</span>
                        </div>
                        <div className='mt-2'>
                            <span className='text-sm'>Nosso objetivo principal é permitir que os consorciados conheçam o valor de suas cotas canceladas e possam negociar livremente no mercado, caso não aceitem nossa oferta. Baseamos nosso trabalho na inovação e no empreendedorismo visionário, princípios que fortalecem nossa credibilidade junto aos clientes e sócios. Com um compromisso contínuo de melhoria e foco em resultados, mantemos nossas diretrizes e missão bem claras.</span>
                        </div>
                    </div>
                    <div style={{ width: '390px' }} >
                        <img src="us_Differential.png" alt="logo" />
                    </div>
                </div>
            </div>
            {/* Componente responsivo para celular */}
            <div className='flex mx-auto my-auto h-sm:flex hidden px-4'>
                <div className='h-full w-full'>
                    <div className='w-full h-full' >
                        <img src="us_Differential-mobile.png" alt="logo" className='w-full' />
                    </div>
                    <div className='mt-2 w-full h-full'>
                        <div>
                            <span style={{ color: '#f5be88' }}>Para você</span>
                        </div>
                        <div className='mt-2'>
                            <span className='text-3xl font-extrabold font-raleway'>Nossa Missão</span>
                        </div>
                        <div className='mt-2'>
                            <span className='text-sm'>Queremos informar os consorciados desistentes ou excluídos sobre a possibilidade de vender seus direitos sobre as parcelas pagas na cota cancelada pelo melhor preço de mercado. Para isso, oferecemos uma calculadora em nosso site que, ao fornecer dados como o valor atual do bem, percentual pago e data de encerramento do grupo, informa imediatamente o valor que oferecemos pela cota cancelada.</span>
                        </div>
                        <div className='mt-2'>
                            <span className='text-sm'>Nosso objetivo principal é permitir que os consorciados conheçam o valor de suas cotas canceladas e possam negociar livremente no mercado, caso não aceitem nossa oferta. Baseamos nosso trabalho na inovação e no empreendedorismo visionário, princípios que fortalecem nossa credibilidade junto aos clientes e sócios. Com um compromisso contínuo de melhoria e foco em resultados, mantemos nossas diretrizes e missão bem claras.</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UsDifferential;