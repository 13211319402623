import React, { useState } from 'react';
import './FormIntermediation.css';

function FormIntermediation() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <form onSubmit={handleSubmit} className='p-8 rounded-lg font-sans shadow-2xl shadow-blue-500/50 h-sm:hidden' style={{ width: '350px', backgroundColor: '#004e80' }}>
        <h3 className='text-center normal-case text-white font-sans mb-6 text-xl font-raleway font-medium'>Formulário</h3>
        <div className='mb-4'>
          <input type="text" className='w-full bg-white rounded-lg h-10 p-3' placeholder='Name' value={name} onChange={(event) => setName(event.target.value)} required />
        </div>
        <div className='mb-4'>
          <input type="text" className='w-full bg-white rounded-lg h-10 p-3' placeholder='Empresa' value={company} onChange={(event) => setCompany(event.target.value)} required />
        </div>
        <div className='mb-4'>
          <input type="text" className='w-full bg-white rounded-lg h-10 p-3' placeholder='Telefone' value={phone} onChange={(event) => setPhone(event.target.value)} required />
        </div>
        <div className='mb-4'>
          <input type="text" className='w-full bg-white rounded-lg h-10 p-3' placeholder='E-mail' value={email} onChange={(event) => setEmail(event.target.value)} required />
        </div>
        <button type="submit" className='w-full text-white border-none rounded-lg py-2 px-4 cursor-pointer h-10 ' style={{ backgroundColor: '#c76f36' }}>Enviar</button>
      </form>
    {/* Componente responsivo para celular */}
      <form onSubmit={handleSubmit} className='p-8 font-sans shadow-2xl shadow-blue-500/50 h-sm:block hidden w-full' style={{backgroundColor: '#004e80' }}>
        <h3 className='text-center normal-case text-white font-sans mb-6 text-xl font-raleway font-medium'>Formulário</h3>
        <div className='mb-4'>
          <input type="text" className='w-full bg-white rounded-lg h-10 p-3' placeholder='Name' value={name} onChange={(event) => setName(event.target.value)} required />
        </div>
        <div className='mb-4'>
          <input type="text" className='w-full bg-white rounded-lg h-10 p-3' placeholder='Empresa' value={company} onChange={(event) => setCompany(event.target.value)} required />
        </div>
        <div className='mb-4'>
          <input type="text" className='w-full bg-white rounded-lg h-10 p-3' placeholder='Telefone' value={phone} onChange={(event) => setPhone(event.target.value)} required />
        </div>
        <div className='mb-4'>
          <input type="text" className='w-full bg-white rounded-lg h-10 p-3' placeholder='E-mail' value={email} onChange={(event) => setEmail(event.target.value)} required />
        </div>
        <button type="submit" className='w-full text-white border-none rounded-lg py-2 px-4 cursor-pointer h-10 ' style={{ backgroundColor: '#c76f36' }}>Enviar</button>
      </form>
    </>
  );
}

export default FormIntermediation;
